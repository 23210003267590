import { createAsyncThunk } from "@reduxjs/toolkit";

// Importa el método que realiza la llamada API
import { 
  getAlarmHistoriesNotAcknowledged as getAlarmHistoriesNotAcknowledgedApi, 
  updateAlarmHistory as updateAlarmHistoryApi, 
  getAlarmHistoriesByZone as getAlarmHistoriesByZoneApi
} from "../../helpers/fakebackend_helper";


export const getAlarmHistoriesByZone = createAsyncThunk("alarmHistory/getAlarmHistoriesByZone", async (zone_id : number) => {
  try {
    // Asegúrate de que la función getAlarmHistoriesByZoneApi acepte zone_id como parámetro
    const response = await getAlarmHistoriesByZoneApi(zone_id);
    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});

export const getAlarmHistoriesNotAcknowledged = createAsyncThunk("alarmHistory/getAlarmHistoriesNotAcknowledged", async () => {
  try {
    const response = await getAlarmHistoriesNotAcknowledgedApi();

    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});

export const updateAlarmHistory = createAsyncThunk("alarmHistory/updateAlarmHistory", async (alarmHistoryData: any) => {
  try {
    const response = await updateAlarmHistoryApi(alarmHistoryData);
    return alarmHistoryData;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});
