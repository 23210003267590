import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import ModalConfirm from './Modals/ModalConfirm';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { SocketContext } from 'context/SocketProvider';

const EnableDisable = ({ onEnableDisable }: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    const param = useParams<{ id: string }>();
    const id = param.id;
    const socket = useContext(SocketContext);
    const selectParametersBackupState = (state: any) => state.ParametersBackup;

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state) => state
    );

    const toggleModal = () => setModalOpen(!modalOpen);

    const { isEnabled } = useSelector(parametersBackupProperties);

    const handleConfirm = (idSelected: number) => {
        console.log("Confirm", idSelected);
        const value = isEnabled[idSelected] ? 0 : 1;

        if (socket) {
            if (idSelected === 18) {
                console.log("habilitando o deshabilitando", value);
                socket.emit('change_parameter_value', {
                    ip: '22.1.1.9',
                    id: idSelected,
                    value: value,
                });
            }

            //onEnableDisable();
            toggleModal();
        }
    };

    return (
        <div className="ms-1 header-item  d-sm-flex">
            <button
                onClick={toggleModal}
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle enable-disable shadow-none">
                <i className='bx bx-key fs-22'></i>
            </button>

            <ModalConfirm
                isOpen={modalOpen}
                toggle={toggleModal}
                title={isEnabled[Number(id)] ? 'Deshabilitar' : 'Habilitar'}
                message={isEnabled[Number(id)] ? 'Ahora mismo la planta está Habilitada ¿Estás seguro de que quieres deshabilitarla?' : 'Ahora mismo la planta está Deshabilitada ¿Estás seguro de que quieres Habilitarla?'}
                onConfirm={handleConfirm}
                onCancel={toggleModal}
                idSelected={Number(id)}
                iconName='ri-information-line display-5 text-warning'
                className={isEnabled[Number(id)] ? 'bg-danger' : 'bg-success'}
            />
        </div>
    );
};



export default EnableDisable;
