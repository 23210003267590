import { createSlice } from "@reduxjs/toolkit";
import {
  createParameterBackup,
  getParametersBackup,
  deleteParameterBackup,
  updateParameterBackup,
  /* getParameterBackup, */
} from "./thunk";
import { unitAndDecimalsDanfossVacon100Flow } from "devices/vacon";

export const initialState: any = {
  parametersBackup: [],
  filteredParameterBackup: [],
  currentParameterPath: { group: "", subgroup_1: "", subgroup_2: "", subgroup_3: "" },
  loadedParametersBackup: false,
  isFetchingParameters: false,
  isBackingUp: false,
  isBackupDone: true,
  valueChanged: false,
  deleteBackup: false,
  isFetchingParametersBackup: {},
  isEnabled: {}
};

const parameterBackupSlice = createSlice({
  name: "parameterBackup",
  initialState,
  reducers: {
    setLoadedParametersBackup: (state, action) => {
      state.loadedParametersBackup = action.payload;
    },
    setIsFetchingParameters: (state, action) => {
      state.isFetchingParameters = action.payload;
    },
    setValueChanged: (state, action) => {
      state.valueChanged = action.payload;
    },

    setIsBackingUp: (state, action) => {
      state.isBackingUp = action.payload;
    },

    setIsBackupDone: (state, action) => {
      state.isBackupDone = action.payload;
    },

    setDeleteBackup: (state, action) => {
      state.deleteBackup = action.payload;
    },
    setFilteredParametersBackup: (state, action) => {
      state.filteredParameterBackup = action.payload;
    },
    setParametersBackup: (state, action) => {
      const { connection_id, values } = action.payload;
      state.parametersBackup[connection_id].parameters = values;
    },
    setFetchingParametersBackup: (state, action) => {
      const { connection_id, isFetching } = action.payload;
      state.isFetchingParametersBackup[connection_id] = isFetching;
    },
    setisEnabled: (state, action) => {
      const { zoneId, isEnabled } = action.payload;
      state.isEnabled[zoneId] = isEnabled;
    },
    /*  updateParameterBackupValue: (state, action) => {
       const parameterInfo = action.payload;
 
       // Verificar si el connection_id en el payload coincide con el actual
       if (!state.parametersBackup[parameterInfo.connection_id]) {
         console.error(`No se encontró parametersBackup para connection_id ${parameterInfo.connection_id}`);
         return;
       }
 
       const connectionParameters = state.parametersBackup[parameterInfo.connection_id].parameters;
 
       // Recorrer y actualizar los parámetros en filteredParameterBackup
       state.filteredParameterBackup.forEach((parameter: any) => {
         if (parameter.parameter_id.toString() === parameterInfo.parameter_id.toString() &&
           parameter.connection_id === parameterInfo.connection_id) {
           console.log(`Actualizando valor en filteredParameterBackup para parameter_id ${parameterInfo.parameter_id} con conexiones ${parameterInfo.connection_id}:`, parameterInfo.value);
           parameter.value = parameterInfo.value;
         }
       });
 
       // Recorrer y actualizar los parámetros en connectionParameters
       connectionParameters.forEach((parameter: any) => {
         if (parameter.parameter_id.toString() === parameterInfo.parameter_id.toString()) {
           parameter.value = parameterInfo.value;
         }
       });
 
     }, */

     updateParameterBackupValueParametrization: (state, action) => {
      const data = action.payload;
      let connectionId;
  
      if (Array.isArray(data)) {
          connectionId = data[0]?.connection_id;
      } else if (data && typeof data === 'object') {
          connectionId = data.connection_id;
      }
    
      if (state.parametersBackup[connectionId]) {
          state.parametersBackup[connectionId].parameters = state.parametersBackup[connectionId].parameters.map((parameter : any) => {
              const update = data.find((u : any) => {
                  const parameterIdMatches = u.parameter_id?.toString() === parameter.parameter_id?.toString();
                  if (parameter.bit_parameter_id != null) {
                      const bitParameterIdMatches = u.bit_parameter_id?.toString() === parameter.bit_parameter_id?.toString();
                      return parameterIdMatches && bitParameterIdMatches;
                  }
                  return parameterIdMatches;
              });
              
              if (update) {
                  if (parameter.bit_parameter_id != null) {
                      const bitPosition = parseInt(parameter.bit_parameter_id, 10);
                      const bitValue = (parseInt(update.value, 10) >> bitPosition) & 1;
                      parameter.value = (parameter.value & ~(1 << bitPosition)) | (bitValue << bitPosition);
                  } else {
                      parameter.value = update.value; // Directamente usar el valor actualizado sin procesar
                  }
              }
              return parameter;
          });
      } else {
          console.error(`No se encontró parametersBackup para el connection_id ${connectionId}`);
      }
  },

     updateParameterBackupValue: (state, action) => {
      const data = action.payload;
  
      let connectionId;
      if (Array.isArray(data)) {
      
        connectionId = data[0]?.connection_id;
      } else if (data && typeof data === 'object') {
       
        connectionId = data.connection_id;
      }
    
      if (state.parametersBackup[connectionId]) {
          state.parametersBackup[connectionId].parameters = state.parametersBackup[connectionId].parameters.map((parameter : any) => {
              const update = data.find((u : any) => {
                  const parameterIdMatches = u.parameter_id?.toString() === parameter.parameter_id?.toString();
                  if (parameter.bit_parameter_id != null) {
                      const bitParameterIdMatches = u.bit_parameter_id?.toString() === parameter.bit_parameter_id?.toString();
                      return parameterIdMatches && bitParameterIdMatches;
                  }
                  return parameterIdMatches;
              });
              
              if (update) {
                  if (parameter.bit_parameter_id != null) {
                      const bitPosition = parseInt(parameter.bit_parameter_id, 10);
                      const bitValue = (parseInt(update.value, 10) >> bitPosition) & 1;
                      parameter.value = (parameter.value & ~(1 << bitPosition)) | (bitValue << bitPosition);
                  } else {
                     
                      const normalizeValue = (value : any) => {
                          if (value == null) return ''; 
                          return value.toString().replace(',', '.');
                      };
  
                      const countDecimals = (value : any) => {
                          const normalizedValue = normalizeValue(value);
                          const index = normalizedValue.indexOf('.');
                          if (index === -1) return 0;
                          return normalizedValue.length - index - 1;
                      };
  
                      const minDecimals = countDecimals(parameter.min);
                      const maxDecimals = countDecimals(parameter.max);
                      const maxDecimalPlaces = Math.max(minDecimals, maxDecimals);
  
                      const normalizedUpdateValue = normalizeValue(update.value);
                      const newValue = parseFloat(normalizedUpdateValue);
                      const factor = Math.pow(10, -maxDecimalPlaces);
                      const adjustedValue = newValue * factor;
  
                      parameter.value = adjustedValue.toFixed(maxDecimalPlaces);
                  }
              }
              return parameter;
          });
      } else {
          console.error(`No se encontró parametersBackup para connection_id ${connectionId}`);
      }
  },
  


    removeParameterBackupByConnectionId: (state, action) => {
      const connectionId = action.payload;
      if (connectionId && state.parametersBackup[connectionId]) {
        state.parametersBackup[connectionId].parameters = [];
      }
    },
    updateFilteredParameterValue: (state, action) => {
      const { data, connectionId } = action.payload;
      state.filteredParameterBackup = state.filteredParameterBackup.map((parameter: any) => {
        if (parameter.connectionId !== connectionId) {
          // Si la connectionId no coincide, devolvemos el parámetro tal cual está.
          return parameter;
        }

        const update = data.find((u: any) => {
          const parameterIdMatches = u.parameter_id?.toString() === parameter.parameter_id?.toString();
          /* if (parameter.bit_parameter_id != null) {
            console.log("parameter bi", parameter);
            const bitParameterIdMatches = u.bit_parameter_id?.toString() === parameter.bit_parameter_id?.toString();
            return parameterIdMatches && bitParameterIdMatches;
          } */
          return parameterIdMatches;
        });
 

        if (update) {
         
          if (parameter.bit_parameter_id != null) {
            const bitPosition = parseInt(parameter.bit_parameter_id, 10); 
          
            const bitValue = (parseInt(update.value, 10) >> bitPosition) & 1;
           
            parameter.value = bitValue.toString();  // Asignar valor del bit específico como string
          } else {
            // Ajuste decimal normal para parámetros sin bit específico
            const normalizeValue = (value: any) => {
              if (value == null) return ''; // Verifica si el valor es null o undefined
              return value.toString().replace(',', '.'); // Reemplaza todas las comas por puntos
            };

            const countDecimals = (value: string) => {
              const normalizedValue = normalizeValue(value);
              const index = normalizedValue.indexOf('.');
              if (index === -1) return 0;
              return normalizedValue.length - index - 1;
            };

            let minDecimals = countDecimals(parameter.min);
            let maxDecimals = countDecimals(parameter.max);
            let maxDecimalPlaces = Math.max(minDecimals, maxDecimals);

            if (data[0]?.connection_id === 74 && update.parameter_id === 73) {
              console.log("valorrrr", update.value);
              console.log("tamaño", update.value.toString().length);
              const valueAsString = update.value.toString();
              if (valueAsString.length === 3) {
                minDecimals = 1;
                maxDecimals = 1;
                maxDecimalPlaces = 1
              } else if (valueAsString.length === 2) {
                minDecimals = 0;
                maxDecimals = 0;
                maxDecimalPlaces = 0
              }
            }

            const normalizedUpdateValue = normalizeValue(update.value);
            const newValue = parseFloat(normalizedUpdateValue);
            const factor = Math.pow(10, -maxDecimalPlaces);
            const adjustedValue = newValue * factor;

            parameter.value = adjustedValue.toFixed(maxDecimalPlaces);
          }
        }

        return parameter;
      });
    }, 



    resetValues: (state) => {
      state.filteredParameterBackup = state.filteredParameterBackup.map((parameter: any) => {
        if (parameter.parameter_type === 'Visualization') {
          return {
            ...parameter,
            value: ""
          };
        }
        return parameter;
      });
    },
    updateParametersBackupByConnection: (state, action) => {
      const { connection_id, parameter_id, parameterValues, parametersBackup } = action.payload;
      const updatedParametersBackup = unitAndDecimalsDanfossVacon100Flow(
        Number(parameter_id),
        parametersBackup,
        parameterValues,
        connection_id.toString()
      );


      state.parametersBackup[connection_id].parameters = updatedParametersBackup;

      const { group, subgroup_1, subgroup_2, subgroup_3 } = state.currentParameterPath;
      const updateParametersFilter = updatedParametersBackup.filter((param: any) =>
        param.group === group &&
        (!subgroup_1 || param.subgroup_1 === subgroup_1) &&
        (!subgroup_2 || param.subgroup_2 === subgroup_2) &&
        (!subgroup_3 || param.subgroup_3 === subgroup_3)
      );

      // Mantener el campo 'favorite' en cada elemento
      const updatedFilteredParameterBackup = updateParametersFilter.map((param: any) => {
        const existingParam = state.filteredParameterBackup.find((parameter: any) => parameter.parameter_id === param.parameter_id);
        return existingParam ? { ...param, favorite: existingParam.favorite } : param;
      });

      state.filteredParameterBackup = updatedFilteredParameterBackup;
    },
    setCurrentParameterPath: (state, action) => {
      state.currentParameterPath = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getParametersBackup.fulfilled, (state, action: any) => {
      const newParameterBackups = action.payload;

      Object.keys(newParameterBackups).forEach((key) => {
        if (!state.parameters || !state.parameters[key]) {
          state.parametersBackup[key] = newParameterBackups[key];
        }
      });
    });

    builder.addCase(getParametersBackup.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    /* builder.addCase(getParameterBackup.fulfilled, (state: any, action: any) => {
      console.log("action.payload", action.payload);
      state.parametersBackup = action.payload;
    });

    builder.addCase(getParameterBackup.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    }); */

    builder.addCase(createParameterBackup.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.parameters.push(action.payload);
      }
    });

    builder.addCase(createParameterBackup.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateParameterBackup.fulfilled, (state: any, action: any) => {
      state.parameters = (state.parameters || []).map((parameter: any) =>
        parameter.id.toString() === action.payload.id.toString()
          ? { ...parameter, ...action.payload }
          : parameter
      );
    });

    builder.addCase(updateParameterBackup.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteParameterBackup.fulfilled, (state: any, action: any) => {
      const connectionId = action.payload;
      if (connectionId && state.parametersBackup[connectionId]) {
        delete state.parametersBackup[connectionId];
      }
    });

    builder.addCase(deleteParameterBackup.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export const {
  setFilteredParametersBackup,
  updateParameterBackupValue,
  updateParameterBackupValueParametrization,
 updateFilteredParameterValue,
  updateParametersBackupByConnection,
  resetValues,
  setIsBackingUp,
  setCurrentParameterPath,
  setParametersBackup,
  setIsFetchingParameters,
  removeParameterBackupByConnectionId,
  setLoadedParametersBackup,
  setValueChanged,
  setIsBackupDone,
  setDeleteBackup,
  setFetchingParametersBackup,
  setisEnabled
} = parameterBackupSlice.actions;
export default parameterBackupSlice.reducer;
