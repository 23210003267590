import { createSlice } from "@reduxjs/toolkit";
import { getAlarmHistoriesNotAcknowledged, updateAlarmHistory, getAlarmHistoriesByZone  } from "./thunk";

export const initialState: any = {
  alarmHistories: [],
  alarmHistoriesNotAcknowledged: [],
  activeAlarms: [],
  error: null,
  loading: false,
};

const alarmHistorySlice = createSlice({
  name: "alarmHistory",
  initialState,
  reducers: {
    addActiveAlarm: (state, action: any) => {
      const alarm = action.payload;
    
      // Verifica si ya existe el alarm_history_id en el array de activeAlarms
      const exists = state.activeAlarms.some(
        (existingAlarm: any) => existingAlarm.alarm_history_id === alarm.alarm_history_id
      );
    
      // Si no existe y no tiene la propiedad 'fault_name', añade la alarma
      if (!exists && !alarm.fault_name) {

        state.activeAlarms = [...state.activeAlarms, alarm];
      } else {
      }
    },

    resetActiveAlarms: (state) => {
      state.activeAlarms = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAlarmHistoriesNotAcknowledged.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAlarmHistoriesNotAcknowledged.fulfilled, (state, action) => {
      state.loading = false;
      state.alarmHistoriesNotAcknowledged = action.payload;
    });
    builder.addCase(getAlarmHistoriesNotAcknowledged.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories';
    });

    builder.addCase(getAlarmHistoriesByZone.pending, (state) => {
   
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAlarmHistoriesByZone.fulfilled, (state, action) => {
      state.loading = false;
      state.alarmHistories = action.payload;
    });
    builder.addCase(getAlarmHistoriesByZone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories by zone';
    });

    builder.addCase(updateAlarmHistory.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.activeAlarms = state.activeAlarms.filter(
          (alarm: any) => alarm.alarm_history_id !== action.payload.id
        );
      }
    });
  },
});
export const {addActiveAlarm,resetActiveAlarms} = alarmHistorySlice.actions;
export default alarmHistorySlice.reducer;
