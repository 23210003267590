import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};


// Register Method
export const postFakeRegister = (data: any) => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (url: string, data: any) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// Login Method check user
export const checkToken = (token: string) =>
  api.get(url.CHECK_TOKEN_USER, { headers: { Authorization: `${token}` } });

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

// Calendar
// get Events
export const getEvents = () => api.get(url.GET_EVENTS, '');

// get Events
export const getCategories = () => api.get(url.GET_CATEGORIES, '');

// get Upcomming Events
export const getUpCommingEvent = () => api.get(url.GET_UPCOMMINGEVENT, '');

// add Events
export const addNewEvent = (event: any) => api.create(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = (event: any) => api.put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = (event: any) => api.delete(url.DELETE_EVENT, { headers: { event } });

// Chat
// get Contact
export const getDirectContact = () => api.get(url.GET_DIRECT_CONTACT, '');

// get Messages
export const getMessages = (roomId: any) => api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// add Message
export const addMessage = (message: any) => api.create(url.ADD_MESSAGE, message);

// add Message
export const deleteMessage = (message: any) => api.delete(url.DELETE_MESSAGE, { headers: { message } });


// MailBox
//get Mail
export const getMailDetails = () => api.get(url.GET_MAIL_DETAILS, '');

// delete Mail
export const deleteMail = (forId: any) => api.delete(url.DELETE_MAIL, { headers: { forId } });

// unread Mail
export const unreadMail = (forId: any) => api.delete(url.UNREAD_MAIL, { headers: { forId } });

// star Mail
export const staredMail = (forId: any) => api.delete(url.STARED_MAIL, { headers: { forId } });

// label Mail
export const labelMail = (forId: any) => api.delete(url.LABEL_MAIL, { headers: { forId } });

// trash Mail
export const trashMail = (forId: any) => api.delete(url.TRASH_MAIL, { headers: { forId } });

// Ecommerce
// get Products
export const getProducts = () => api.get(url.GET_PRODUCTS, '');

// delete Product
export const deleteProducts = (product: any) => api.delete(url.DELETE_PRODUCT, { headers: { product } });

// add Products
export const addNewProduct = (product: any) => api.create(url.ADD_NEW_PRODUCT, product);
// update Products
export const updateProduct = (product: any) => api.update(url.UPDATE_PRODUCT, product);

// get Orders
export const getOrders = () => api.get(url.GET_ORDERS, '');

// add Order
export const addNewOrder = (order: any) => api.create(url.ADD_NEW_ORDER, order);

// update Order
export const updateOrder = (order: any) => api.update(url.UPDATE_ORDER, order);

// delete Order
export const deleteOrder = (order: any) => api.delete(url.DELETE_ORDER, { headers: { order } });

// get Customers
export const getCustomers = () => api.get(url.GET_CUSTOMERS, '');

// add Customers
export const addNewCustomer = (customer: any) => api.create(url.ADD_NEW_CUSTOMER, customer);

// update Customers
export const updateCustomer = (customer: any) => api.update(url.UPDATE_CUSTOMER, customer);

// delete Customers
export const deleteCustomer = (customer: any) => api.delete(url.DELETE_CUSTOMER, { headers: { customer } });

// get Sellers
export const getSellers = () => api.get(url.GET_SELLERS, '');

// Project
// get Project list 
export const getProjectList = () => api.get(url.GET_PROJECT_LIST, '');

// Tasks
// get Task
export const getTaskList = () => api.get(url.GET_TASK_LIST, '');

// add Task
export const addNewTask = (task: any) => api.create(url.ADD_NEW_TASK, task);

// update Task
export const updateTask = (task: any) => api.update(url.UPDATE_TASK, task);

// delete Task
export const deleteTask = (task: any) => api.delete(url.DELETE_TASK, { headers: { task } });

// Kanban Board
export const getTasks = () => api.get(url.GET_TASKS, "");
export const addNewTasks = (card: any) => api.create(url.ADD_TASKS, card)
export const updateTasks = (card: any) => api.put(url.UPDATE_TASKS, card)
export const deleteTasks = (card: any) => api.delete(url.DELETE_TASKS, { headers: { card } })

// CRM
// get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS, '');

// add Contact
export const addNewContact = (contact: any) => api.create(url.ADD_NEW_CONTACT, contact);

// update Contact
export const updateContact = (contact: any) => api.update(url.UPDATE_CONTACT, contact);

// delete Contact
export const deleteContact = (contact: any) => api.delete(url.DELETE_CONTACT, { headers: { contact } });


// get Deals
export const getDeals = () => api.get(url.GET_DEALS, '');

// get Leads
export const getLeads = () => api.get(url.GET_LEADS, '');

// add Lead
export const addNewLead = (lead: any) => api.create(url.ADD_NEW_LEAD, lead);

// update Lead
export const updateLead = (lead: any) => api.update(url.UPDATE_LEAD, lead);

// delete Lead
export const deleteLead = (lead: any) => api.delete(url.DELETE_LEAD, { headers: { lead } });

// Crypto
// Transation
export const getTransationList = () => api.get(url.GET_TRANSACTION_LIST, '');

// Order List
export const getOrderList = () => api.get(url.GET_ORDRER_LIST, '');

// Invoice
//get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES, '');

// add Invoice
export const addNewInvoice = (invoice: any) => api.create(url.ADD_NEW_INVOICE, invoice);

// update Invoice
export const updateInvoice = (invoice: any) => api.update(url.UPDATE_INVOICE + '/' + invoice._id, invoice);

// delete Invoice
export const deleteInvoice = (invoice: any) => api.delete(url.DELETE_INVOICE + '/' + invoice);

// Support Tickets 
// Tickets
export const getTicketsList = () => api.get(url.GET_TICKETS_LIST, '');

// add Tickets 
export const addNewTicket = (ticket: any) => api.create(url.ADD_NEW_TICKET, ticket);

// update Tickets 
export const updateTicket = (ticket: any) => api.update(url.UPDATE_TICKET, ticket);

// delete Tickets 
export const deleteTicket = (ticket: any) => api.delete(url.DELETE_TICKET, { headers: { ticket } });

// Dashboard Analytics

// Sessions by Countries
export const getAllData = () => api.get(url.GET_ALL_DATA, '');
export const getHalfYearlyData = () => api.get(url.GET_HALFYEARLY_DATA, '');
export const getMonthlyData = () => api.get(url.GET_MONTHLY_DATA, '');

// Audiences Metrics
export const getAllAudiencesMetricsData = () => api.get(url.GET_ALLAUDIENCESMETRICS_DATA, '');
export const getMonthlyAudiencesMetricsData = () => api.get(url.GET_MONTHLYAUDIENCESMETRICS_DATA, '');
export const getHalfYearlyAudiencesMetricsData = () => api.get(url.GET_HALFYEARLYAUDIENCESMETRICS_DATA, '');
export const getYearlyAudiencesMetricsData = () => api.get(url.GET_YEARLYAUDIENCESMETRICS_DATA, '');

// Users by Device
export const getTodayDeviceData = () => api.get(url.GET_TODAYDEVICE_DATA, '');
export const getLastWeekDeviceData = () => api.get(url.GET_LASTWEEKDEVICE_DATA, '');
export const getLastMonthDeviceData = () => api.get(url.GET_LASTMONTHDEVICE_DATA, '');
export const getCurrentYearDeviceData = () => api.get(url.GET_CURRENTYEARDEVICE_DATA, '');

// Audiences Sessions by Country
export const getTodaySessionData = () => api.get(url.GET_TODAYSESSION_DATA, '');
export const getLastWeekSessionData = () => api.get(url.GET_LASTWEEKSESSION_DATA, '');
export const getLastMonthSessionData = () => api.get(url.GET_LASTMONTHSESSION_DATA, '');
export const getCurrentYearSessionData = () => api.get(url.GET_CURRENTYEARSESSION_DATA, '');

// Dashboard CRM

// Balance Overview
export const getTodayBalanceData = () => api.get(url.GET_TODAYBALANCE_DATA, '');
export const getLastWeekBalanceData = () => api.get(url.GET_LASTWEEKBALANCE_DATA, '');
export const getLastMonthBalanceData = () => api.get(url.GET_LASTMONTHBALANCE_DATA, '');
export const getCurrentYearBalanceData = () => api.get(url.GET_CURRENTYEARBALANCE_DATA, '');

// Dial Type
export const getTodayDealData = () => api.get(url.GET_TODAYDEAL_DATA, '');
export const getWeeklyDealData = () => api.get(url.GET_WEEKLYDEAL_DATA, '');
export const getMonthlyDealData = () => api.get(url.GET_MONTHLYDEAL_DATA, '');
export const getYearlyDealData = () => api.get(url.GET_YEARLYDEAL_DATA, '');

// Sales Forecast
export const getOctSalesData = () => api.get(url.GET_OCTSALES_DATA, '');
export const getNovSalesData = () => api.get(url.GET_NOVSALES_DATA, '');
export const getDecSalesData = () => api.get(url.GET_DECSALES_DATA, '');
export const getJanSalesData = () => api.get(url.GET_JANSALES_DATA, '');

// Dashboard Ecommerce
// Revenue
export const getAllRevenueData = () => api.get(url.GET_ALLREVENUE_DATA, '');
export const getMonthRevenueData = () => api.get(url.GET_MONTHREVENUE_DATA, '');
export const getHalfYearRevenueData = () => api.get(url.GET_HALFYEARREVENUE_DATA, '');
export const getYearRevenueData = () => api.get(url.GET_YEARREVENUE_DATA, '');


// Dashboard Crypto
// Portfolio
export const getBtcPortfolioData = () => api.get(url.GET_BTCPORTFOLIO_DATA, '');
export const getUsdPortfolioData = () => api.get(url.GET_USDPORTFOLIO_DATA, '');
export const getEuroPortfolioData = () => api.get(url.GET_EUROPORTFOLIO_DATA, '');

// Market Graph
export const getAllMarketData = () => api.get(url.GET_ALLMARKETDATA_DATA, '');
export const getYearMarketData = () => api.get(url.GET_YEARMARKET_DATA, '');
export const getMonthMarketData = () => api.get(url.GET_MONTHMARKET_DATA, '');
export const getWeekMarketData = () => api.get(url.GET_WEEKMARKET_DATA, '');
export const getHourMarketData = () => api.get(url.GET_HOURMARKET_DATA, '');

// Dashboard Project
// Project Overview
export const getAllProjectData = () => api.get(url.GET_ALLPROJECT_DATA, '');
export const getMonthProjectData = () => api.get(url.GET_MONTHPROJECT_DATA, '');
export const gethalfYearProjectData = () => api.get(url.GET_HALFYEARPROJECT_DATA, '');
export const getYearProjectData = () => api.get(url.GET_YEARPROJECT_DATA, '');

// Project Status
export const getAllProjectStatusData = () => api.get(url.GET_ALLPROJECTSTATUS_DATA, '');
export const getWeekProjectStatusData = () => api.get(url.GET_WEEKPROJECTSTATUS_DATA, '');
export const getMonthProjectStatusData = () => api.get(url.GET_MONTHPROJECTSTATUS_DATA, '');
export const getQuarterProjectStatusData = () => api.get(url.GET_QUARTERPROJECTSTATUS_DATA, '');

// Dashboard NFT
// Marketplace
export const getAllMarketplaceData = () => api.get(url.GET_ALLMARKETPLACE_DATA, '');
export const getMonthMarketplaceData = () => api.get(url.GET_MONTHMARKETPLACE_DATA, '');
export const gethalfYearMarketplaceData = () => api.get(url.GET_HALFYEARMARKETPLACE_DATA, '');
export const getYearMarketplaceData = () => api.get(url.GET_YEARMARKETPLACE_DATA, '');

// Project
export const addProjectList = (project: any) => api.create(url.ADD_NEW_PROJECT, project);
export const updateProjectList = (project: any) => api.put(url.UPDATE_PROJECT, project);
export const deleteProjectList = (project: any) => api.delete(url.DELETE_PROJECT, { headers: { project } });

// Pages > Team
export const getTeamData = () => api.get(url.GET_TEAMDATA, '');
export const deleteTeamData = (team: any) => api.delete(url.DELETE_TEAMDATA, { headers: { team } });
export const addTeamData = (team: any) => api.create(url.ADD_NEW_TEAMDATA, team);
export const updateTeamData = (team: any) => api.put(url.UPDATE_TEAMDATA, team);

// File Manager

// Folder
export const getFolders = () => api.get(url.GET_FOLDERS, '');
export const deleteFolder = (folder: any) => api.delete(url.DELETE_FOLDER, { headers: { folder } });
export const addNewFolder = (folder: any) => api.create(url.ADD_NEW_FOLDER, folder);
export const updateFolder = (folder: any) => api.put(url.UPDATE_FOLDER, folder);

// File
export const getFiles = () => api.get(url.GET_FILES, '');
export const deleteFile = (file: any) => api.delete(url.DELETE_FILE, { headers: { file } });
export const addNewFile = (file: any) => api.create(url.ADD_NEW_FILE, file);
export const updateFile = (file: any) => api.put(url.UPDATE_FILE, file);

// To Do
export const getTodos = () => api.get(url.GET_TODOS, '');
export const deleteTodo = (todo: any) => api.delete(url.DELETE_TODO, { headers: { todo } });
export const addNewTodo = (todo: any) => api.create(url.ADD_NEW_TODO, todo);
export const updateTodo = (todo: any) => api.put(url.UPDATE_TODO, todo);

// To do Project
export const getProjects = () => api.get(url.GET_PROJECTS, '');
export const addNewProject = (project: any) => api.create(url.ADD_NEW_TODO_PROJECT, project);

//API Key
export const getAPIKey = () => api.get(url.GET_API_KEY, '');

//Job Application
export const getJobApplicationList = () => api.get(url.GET_APPLICATION_LIST, '');
export const addNewJobApplicationList = (job: any) => api.create(url.ADD_NEW_APPLICATION_LIST, job);
export const updateJobApplicationList = (job: any) => api.put(url.UPDATE_APPLICATION_LIST, job);
export const deleteJobApplicationList = (job: any) => api.delete(url.DELETE_APPLICATION_LIST, { headers: { job } });

// candidate list
export const getJobCandidateList = () => api.get(url.GET_CANDIDATE, '');
export const addJobCandidate = (candidate: any) => api.create(url.ADD_NEW_CANDIDATE, candidate);
export const updateJobCandidate = (candidate: any) => api.update(url.UPDATE_CANDIDATE, candidate);
export const deleteJobCandidate = (candidate: any) => api.delete(url.DELETE_CANDIDATE, { headers: { candidate } });

// category list
export const getcategoryList = () => api.get(url.GET_CATEGORY_LIST, '');
export const addcategoryList = (category: any) => api.create(url.ADD_CATEGORY_LIST, category);

// grid
export const getCandidateGrid = () => api.get(url.GET_CANDIDATE_GRID, '');
export const addCandidateGrid = (category: any) => api.create(url.ADD_CANDIDATE_GRID, category);


// ------INSTALLATIONS------

// get Installations
export const getInstallations = () => api.get(url.GET_INSTALLATIONS, '');
//create Installation
export const createInstallation = (installation: any) => api.create(url.ADD_NEW_INSTALLATION, installation);
// update Installation
export const updateInstallation = (installation: any) => api.update(url.UPDATE_INSTALLATION, installation);
//reorder Installations
export const reorderInstallations = (installations: any) => api.update(url.REORDER_INSTALLATIONS, installations);
// delete Installation
export const deleteInstallation = (installation: any) => api.delete(url.DELETE_INSTALLATION, { headers: { installation } });


// ------INSTALLATION ZONES------

// get InstallationZones
export const getInstallationZones = (installationId: number) => api.get(url.GET_INSTALLATIONZONES, { headers: { installationId } });

// get InstallationZonesByUser
export const getInstallationZonesByUser = (userId: number) => api.get(url.GET_INSTALLATIONZONES_BY_USER, { headers: { userId } });

//create InstallationZone
export const createInstallationZone = (installationZone: any) => api.create(url.ADD_NEW_INSTALLATION_ZONE, installationZone);

// update InstallationZone
export const updateInstallationZone = (installationZone: any) => api.update(url.UPDATE_INSTALLATION_ZONE, installationZone);

//update userInstallationZone
export const updateUserInstallationZone = (userInstallationZone: any) => api.update(url.UPDATE_USER_INSTALLATION_ZONE, userInstallationZone);

// reorder InstallationZones
export const reorderInstallationZones = (installationZones: any) => api.update(url.REORDER_INSTALLATION_ZONES, installationZones);

// delete InstallationZone
export const deleteInstallationZone = (installationZone: any) => api.delete(url.DELETE_INSTALLATION_ZONE, { headers: { installationZone } });


// ------STATUS------

// get Status
export const getStatus = (zoneId: number) => api.get(url.GET_STATUS, { headers: { zoneId } });

//create Status
export const createStatus = (status: any) => api.create(url.ADD_NEW_STATUS, status);

// update Status
export const updateStatus = (status: any) => api.update(url.UPDATE_STATUS, status);

// delete Status
export const deleteStatus = (status: any) => api.delete(url.DELETE_STATUS, { headers: { status } });


// ------STATUS ZONE------

// get StatusZone
export const getStatusZone = (zoneId: number) => api.get(url.GET_STATUS_ZONE, { headers: { zoneId } });

//create Status
export const createStatusZone = (status: any) => api.create(url.ADD_NEW_STATUS_ZONE, status);

// update Status
export const updateStatusZone = (status: any) => api.update(url.UPDATE_STATUS_ZONE, status);

// delete Status
export const deleteStatusZone = (status: any) => api.delete(url.DELETE_STATUS_ZONE, { headers: { status } });

// update Status
export const reorderedStatusZones = (statusZones: any) => api.update(url.REORDERED_STATUS_ZONE, statusZones);



// ------USERS------

// get Users
export const getUsers = () => api.get(url.GET_USERS, '');

// get Supervisors By Zone
export const getSupervisorsByZone = (zoneId: any) => {
  return api.get(url.GET_SUPERVISORS_BY_ZONE, { params: { zone_id: zoneId } });
};

//create User
export const createUser = (user: any) => api.create(url.ADD_NEW_USER, user);

// update User
export const updateUser = (user: any) => api.update(url.UPDATE_USER, user);

// delete User
export const deleteUser = (user: any) => api.delete(url.DELETE_USER, { headers: { user } });

//reset User Password
export const resetUserPassword = (user: any) => api.create(url.RESET_USER_PASSWORD, user);

// changeUserPassword
export const changeUserPassword = (passwordInfo: any) => api.create(url.CHANGE_USER_PASSWORD, passwordInfo);


// ------DEVICES------

// get Devices
export const getDevices = () => api.get(url.GET_DEVICES, '');

// get Device
export const getDevice = (deviceId: any) => {
  return api.get(url.GET_DEVICE, { params: { device_id: deviceId } });
};

//create Device
export const createDevice = (device: any) => api.create(url.ADD_NEW_DEVICE, device);

// update Device
export const updateDevice = (device: any) => api.update(url.UPDATE_DEVICE, device);

// delete Device
export const deleteDevice = (device: any) => api.delete(url.DELETE_DEVICE, { headers: { device } });


// ------PARAMETERS-------

// get Parameters
export const getParameters = (connectionId: number) => api.get(url.GET_PARAMETERS, { params: { connection_id: connectionId } });

// get ValueModbusByParameter
export const getValueModbusByParameter = (parameterInfo: any) => api.get(url.GET_VALUE_MODBUS_BY_PARAMETER, parameterInfo);

// get Parameter
export const getParameter = (parameterId: any) => {
  return api.get(url.GET_PARAMETER, { params: { parameter_id: parameterId } });
};

//create Parameter
export const createParameter = (parameter: any) => api.create(url.ADD_NEW_PARAMETER, parameter);

// update Parameter
export const updateParameter = (parameterInfo: any) => { return api.update(url.UPDATE_PARAMETER, parameterInfo); };

// delete Parameter
export const deleteParameter = (parameter: any) => api.delete(url.DELETE_PARAMETER, { headers: { parameter } });


// ------PARAMETER VALUES-------

// get ParameterValues
export const getParameterValues = (parameterId: any) => {
  return api.get(url.GET_PARAMETER_VALUES, { params: { parameter_id: parameterId } });
};

// get ParameterValuesByConnection
export const getParameterValuesByConnection = (connectionId: any) => {
  return api.get(url.GET_PARAMETERS_VALUES_BY_CONNECTION, { params: { connection_id: connectionId } });
};

//create ParameterValues
export const createParameterValues = (parameter: any) => api.create(url.ADD_NEW_PARAMETER_VALUES, parameter);

// update ParameterValues
export const updateParameterValues = (parameterInfo: any) => { return api.update(url.UPDATE_PARAMETER_VALUES, parameterInfo); };

// delete ParameterValues
export const deleteParameterValues = (parameter: any) => api.delete(url.DELETE_PARAMETER_VALUES, { headers: { parameter } });


// ------FAVORITES-------

// get FavoritesByConnection
export const getFavoritesByConnectionId = (connectionId: any) => {
  return api.get(url.GET_FAVORITES_BY_CONNECTION_ID, { params: { connection_id: connectionId } });
};

export const checkAnalogicInFavorites = (AnalogicInfo: any) => api.create(url.CHECK_ANALOGIC_IN_FAVORITES, AnalogicInfo);

//create Favorite
export const createFavorite = (favorite: any) => api.create(url.ADD_NEW_FAVORITE, favorite);

// update Favorite
export const updateFavorite = (favoriteInfo: any) => { return api.update(url.UPDATE_FAVORITE, favoriteInfo); };

// delete Favorite
export const deleteFavorite = (favoriteId: any) => api.delete(url.DELETE_FAVORITE, { headers: { favoriteId } });

// is Analogic Used
export const isAnalogicUsed = (ip: string, parameter_id: number, device: string) => {
  return api.get(url.IS_ANALOGIC_USED, { params: { ip, parameter_id, device } });
};

// reorder Favorites
export const reorderFavorites = (favorites: any[]) => {
  return api.create(url.REORDER_FAVORITES, { favorites });
};

// getDashboardInfo
export const getDashboardInfo = (zoneId: any) => {
  return api.get(url.GET_DASHBOARD_INFO, { params: { zone_id: zoneId } });
};

export const getLinkedFavorites = (favorite_id: any) => {
  return api.get(url.GET_LINKED_FAVORITES, { params: { favorite_id: favorite_id } });
};

// get Linked Variables By Connection
export const getLinkedVariablesByConnection = (connection_id: any) => {
  return api.get(url.GET_LINKED_VARIABLES_BY_CONNECTION, { params: { connection_id: connection_id } });
};

//create LinkedParameter
export const createLinkedParameter = (linkedParameter: any) => api.create(url.ADD_LINKED_PARAMETER, linkedParameter);

// delete LinkedParameter
export const deleteLinkedParameter = (favoriteId: any) => api.delete(url.DELETE_LINKED_PARAMETER, { headers: { favoriteId } });

// ------REGISTERS------

// get Daily Average Registers Between Dates
export const getDailyAverageRegistersBetweenDates = (startDate: string, endDate: string, zoneId: string) => {
  return api.get(url.GET_DAILY_AVERAGE_REGISTERS_BETWEEN_DATES, { params: { start_date: startDate, end_date: endDate, zone_id: zoneId } });
};

// get Registers By Date Range
export const getRegistersBetweenDates = (startDate: string, endDate: string, zoneId: string) => {
  return api.get(url.GET_REGISTERS_BETWEEN_DATE, { params: { start_date: startDate, end_date: endDate, zone_id: zoneId } });
};

// refresh Favorites Records
export const refreshFavoritesRecords = (zoneId: string) => {
  return api.get(url.REFRESH_FAVORITES_RECORDS, { params: { zone_id: zoneId } });
};


//---PARAMETER BACKUPS---

// get ParameterBackups
export const getParametersBackup = (connectionId: number) => api.get(url.GET_PARAMETER_BACKUPS, { params: { connection_id: connectionId } });

// get ParameterBackup
export const getParameterBackup = (parameterId: any) => {
  return api.get(url.GET_PARAMETER_BACKUP, { params: { parameter_id: parameterId } });
};

//  backupParametersByConnection
export const backupParametersByConnection = (backupInfo: any) => api.create(url.GET_BACKUP_PARAMETERS_BY_CONNECTION, backupInfo);

// create ParameterBackup
export const createParameterBackup = (parameter: any) => api.create(url.ADD_NEW_PARAMETER_BACKUP, parameter);

// update ParameterBackup
export const updateParameterBackup = (parameterInfo: any) => { return api.update(url.UPDATE_PARAMETER_BACKUP, parameterInfo); };

// delete ParameterBackup
export const deleteParameterBackup = (connectionId: any) => api.delete(url.DELETE_PARAMETER_BACKUP, { headers: { connectionId } });



// ------CONNECTIONS------

// get Connections
export const getConnections = () => api.get(url.GET_CONNECTIONS, '');

// get Unconfigured Connections
export const getUnconfiguredActiveConnections = (zoneId: any) => {
  return api.get(url.GET_UNCONFIGURED_ACTIVE_CONNECTIONS, { params: { zone_id: zoneId } });
};

// reorder InstallationZones
export const reorderConnections = (connections: any) => api.update(url.REORDER_CONNECTIONS, connections);

//create Connection
export const createConnection = (connection: any) => api.create(url.ADD_NEW_CONNECTION, connection);

// update Connection
export const updateConnection = (connection: any) => api.update(url.UPDATE_CONNECTION, connection);

// delete Connection
export const deleteConnection = (connectionId: any) => api.delete(url.DELETE_CONNECTION, { headers: { connectionId } });


//FAULT HISTORY
export const getFaultHistoriesNotAcknowledged = () => api.get(url.GET_FAULT_HISTORIES_IS_NOT_ACKNOWLEDGED, '');
export const getFaultHistoriesByZone = (zoneId: number) => {
  return api.get(url.GET_FAULT_HISTORIES_BY_ZONE, { params: { zone_id: zoneId } });
};
export const updateFaultHistory = (connection: any) => api.update(url.UPDATE_FAULT_HISTORY, connection);

//ZONE EMAIL
export const getZoneEmailByUser = (userId: any) => {
  return api.get(url.GET_ZONE_EMAIL_BY_ZONE, { params: { user_id: userId } });
};
export const createZoneEmailByUser = (zoneEmail: any) => api.create(url.ADD_ZONE_EMAIL_BY_ZONE, zoneEmail);
export const deleteZoneEmailByUser = (zoneEmail: any) => api.delete(url.DELETE_ZONE_EMAIL_BY_ZONE, { headers: { zoneEmail } });

//CALCULATED PARAMETERS
export const getCalculatedParametersByZone = (zoneId: any) => {
  return api.get(url.GET_CALCULATED_PARAMETERS_BY_ZONE, { params: { zone_id: zoneId } });
};

// StartupRegistration
export const getStartupRegistrationByZone = (zoneId: any) => {
  return api.get(url.GET_STARTUP_REGISTRATION_BY_ZONE, { params: { zone_id: zoneId } });
};


//ALARM HISTORY
export const getAlarmHistoriesNotAcknowledged = () => api.get(url.GET_ALARM_HISTORIES_IS_NOT_ACKNOWLEDGED, '');
export const getAlarmHistoriesByZone = (zoneId: number) => {
  return api.get(url.GET_ALARM_HISTORIES_BY_ZONE, { params: { zone_id: zoneId } });
};

export const updateAlarmHistory = (connection: any) => api.update(url.UPDATE_ALARM_HISTORY, connection);

export const createCalculatedParameter = (calculatedParameter: any) => api.create(url.ADD_NEW_CALCULATED_PARAMETER, calculatedParameter);
export const updateCalculatedParameter = (calculatedParameter: any) => api.update(url.UPDATE_CALCULATED_PARAMETER, calculatedParameter);
export const deleteCalculatedParameter = (calculatedParameter: any) => api.delete(url.DELETE_CALCULATED_PARAMETER, { headers: { calculatedParameter } });


// ALARMS AND FAULTS
export const getAlarmsAndFaultsByConnection = (connectionId: any) => {
  return api.get(url.GET_ALARMS_AND_FAULTS_BY_CONNECTION, { params: { connection_id: connectionId } });
};
export const createCustomAlarmOrFault = (alarmOrFaultData: any) => api.create(url.CREATE_CUSTOM_ALARM_OR_FAULT, alarmOrFaultData);
export const updateCustomAlarmOrFault = (alarmOrFaultData: any) => api.update(url.UPDATE_CUSTOM_ALARM_OR_FAULT, alarmOrFaultData);
export const deleteCustomAlarmOrFault = (alarmOrFaultData: any) => api.delete(url.DELETE_CUSTOM_ALARM_OR_FAULT, { headers: { alarmOrFaultData } });

// FAULTS (AND ALARMS)
export const getFaultsByConnectionID = (connectionID: number) => {
  return api.get(url.GET_FAULTS_BY_CONNECTION_ID, { params: { connection_id: connectionID } });
};


// ------ROLES------

// get Roles
export const getRoles = () => api.get(url.GET_ROLES, '');

//create Role
export const createRole = (role: any) => api.create(url.ADD_NEW_ROLE, role);

// update Role
export const updateRole = (role: any) => api.update(url.UPDATE_ROLE, role);

// delete Role
export const deleteRole = (role: any) => api.delete(url.DELETE_ROLE, { headers: { role } });



//------COMPANIES------

// get Companies
export const getCompanies = () => api.get(url.GET_COMPANIES, '');

// add Companies
export const createCompany = (company: any) => api.create(url.ADD_NEW_COMPANY, company);

// update Companies
export const updateCompany = (company: any) => api.update(url.UPDATE_COMPANY, company);

// delete Companies
export const deleteCompany = (company: any) => api.delete(url.DELETE_COMPANY, { headers: { company } });

//Main Variables
export const reorderMainVariables = (mainVariables: any) => api.update(url.REORDER_MAIN_VARIABLES, mainVariables);


//INSTALLATIONCALCULATEDPARAMETERS

//CALCULATED PARAMETERS
export const getInstallationCalculatedParameters = (installationId: any) => {
  return api.get(url.GET_INSTALLATION_CALCULATED_PARAMETERS, { params: { installation_id: installationId } });
};
export const createInstallationCalculatedParameter = (installationCalculatedParameter: any) => api.create(url.ADD_NEW_INSTALLATION_CALCULATED_PARAMETER, installationCalculatedParameter);
export const updateInstallationCalculatedParameter = (installationCalculatedParameter: any) => api.update(url.UPDATE_INSTALLATION_CALCULATED_PARAMETER, installationCalculatedParameter);
export const deleteInstallationCalculatedParameter = (installationCalculatedParameter: any) => api.delete(url.DELETE_INSTALLATION_CALCULATED_PARAMETER, { headers: { installationCalculatedParameter } });


// ------INSTALLATION REGISTERS------

export const getDailyAverageInstallationRegistersBetweenDates = (startDate: string, endDate: string, installationId: string) => {
  return api.get(url.GET_DAILY_AVERAGE_INSTALLATION_REGISTERS_BETWEEN_DATES, { params: { start_date: startDate, end_date: endDate, installationId: installationId } });
};

export const getIntallationRegistersBetweenDates = (startDate: string, endDate: string, installationId: string) => {
  return api.get(url.GET_INSTALLATION_REGISTERS_BETWEEN_DATE, { params: { start_date: startDate, end_date: endDate, installationId: installationId } });
};


// SYNOPTIC IMAGES CONFIG

export const getSynopticImageConfigByZone = (zoneId: any) => {
  return api.get(url.GET_SYNOPTIC_IMAGE_CONFIG_BY_ZONE, { params: { zone_id: zoneId } });
};

export const createSynopticImageConfig = (synopticImageConfig: any) => api.create(url.ADD_NEW_SYNOPTIC_IMAGE_CONFIG, synopticImageConfig);
export const updateSynopticImageConfig = (synopticImageConfig: any) => api.update(url.UPDATE_SYNOPTIC_IMAGE_CONFIG, synopticImageConfig);
export const deleteSynopticImageConfig = (synopticImageConfig: any) => api.delete(url.DELETE_SYNOPTIC_IMAGE_CONFIG, { headers: { synopticImageConfig } });


// SECTION DATA CONFIG
export const getSectionDataConfigByZone = (zoneId: any) => {
  return api.get(url.GET_SECTION_DATA_CONFIG_BY_ZONE, { params: { zone_id: zoneId } });
};


