import { createSlice } from "@reduxjs/toolkit";
import {
  createFavorite,
  getFavoritesByConnectionId,
  deleteFavorite,
  updateFavorite,
  reorderFavorites,
  getDashboardInfo,
  getLinkedFavorites,
  createLinkedParameter,
  deleteLinkedParameter,
  getLinkedVariablesByConnection,
  resetFavoriteSelected
} from "./thunk";
import { act } from "react-dom/test-utils";



export const initialState: any = {
  favorites: [],
  dashBoardInfo: {},
  linkedFavorites: [],
  linkedFavoritesByConnection: [],
  favoriteIdSelected: null,
  isFetchingFavorites: {}
};


const parameterValuesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    /* createFavorite: (state, action) => {
      const { favorite_id, name, min_scale, max_scale, connection_id} = action.payload;

      const favorite = state.favorites.find((favorite: any) => favorite.id === favorite_id);

      console.log("favorite found: ", favorite)
     
    }, */
    setFetchingFavorites: (state, action) => {
      const { connection_id, isFetching } = action.payload;
      state.isFetchingFavorites[connection_id] = isFetching;
    },
    setFilteredFavorite: (state, action) => {
      state.filteredFavorite = action.payload;
    },

    setSelectedFavoriteId: (state, action) => {
      state.favoriteIdSelected = action.payload;
    },

    setDashBoardInfo: (state, action) => {
      state.dashBoardInfo = action.payload;
    },

    updateDashboardValues: (state, action) => {
      const { data, parametersBackup, zoneId } = action.payload;
      const values = data && data.values;


      values && values.forEach(({ parameter_id, value, bit_parameter_id, idmap_id, connection_id }: any) => {
        if (state.dashBoardInfo[zoneId] && state.dashBoardInfo[zoneId].favorites) {
          const favorites = state.dashBoardInfo[zoneId].favorites.filter((fav: any) => {
              return fav.parameter_id == parameter_id &&
                  ((fav.idmap_id == idmap_id) || (fav.idmap_id === null && idmap_id === "") || (fav.idmap_id === "" && idmap_id === null)) &&
                  fav.connection_id == connection_id;          
          });
      
          favorites.forEach((favorite: any) => {
              const parameterDetails = parametersBackup[connection_id].parameters?.find((p: any) => 
                  Number(p.parameter_id) === favorite.parameter_id && p.bit_parameter_id === favorite.bit_parameter_id);
      
              const countDecimals = (value: string) => {
                  if (!value) return 0;  
                  const normalizedValue = value.replace(',', '.');  
                  const decimalPart = normalizedValue.split('.')[1]; 
                  return decimalPart ? decimalPart.length : 0; 
              };
      
              let minDecimals = countDecimals(parameterDetails && parameterDetails?.min);
              let maxDecimals = countDecimals(parameterDetails && parameterDetails?.max);
              let maxDecimalPlaces = Math.max(minDecimals, maxDecimals);

              if (data[0]?.connection_id === 74 && parameterDetails.parameter_id === 73) {
                const valueAsString = parameterDetails.value.toString();
                if (valueAsString.length === 3) {
                  minDecimals = 1;
                  maxDecimals = 1;
                  maxDecimalPlaces = 1
                } else if (valueAsString.length === 2) {
                  minDecimals = 0;
                  maxDecimals = 0;
                  maxDecimalPlaces = 0
                }
              }
      
              const normalizeValue = (value: any) => {
                  return value && value.toString().replace(',', '.'); 
              };
              const normalizedUpdateValue = normalizeValue(value);
              const newValue = parseFloat(normalizedUpdateValue);
              const factor = Math.pow(10, -maxDecimalPlaces);  
              let adjustedValue = newValue * factor;
      
              if(parameterDetails.bit_parameter_id || parameterDetails.bit_parameter_id == 0) {
                  adjustedValue = (adjustedValue >> parameterDetails.bit_parameter_id) & 1;
              }
      
              if (favorite.max_scale) {
                  adjustedValue = (adjustedValue * favorite.max_scale) / 100;
              }
      
              favorite.value = adjustedValue.toFixed(maxDecimalPlaces);
          });
      }
      

      if (state.dashBoardInfo[zoneId] && state.dashBoardInfo[zoneId].main_variables) {
        const mainVariables = state.dashBoardInfo[zoneId].main_variables.filter((mv: any) => {
            return Number(mv.parameter_id) == Number(parameter_id) &&
                ((mv.idmap_id == idmap_id) || (mv.idmap_id === null && idmap_id === "") || (mv.idmap_id === "" && idmap_id === null)) &&
                mv.connection_id == connection_id;
        });
    
        mainVariables.forEach((mainVariable: any) => {
            const parameterDetails = parametersBackup[connection_id].parameters?.find((p: any) => 
                Number(p.parameter_id) === mainVariable.parameter_id &&
                p.bit_parameter_id === mainVariable.bit_parameter_id
            );
    
            if (parameterDetails) {
              const countDecimals = (value: string) => {
                if (!value) return 0;
                const normalizedValue = value.replace(',', '.');
                const decimalPart = normalizedValue.split('.')[1];
                return decimalPart ? decimalPart.length : 0;
              };


              const minDecimals = countDecimals(parameterDetails.min);
              const maxDecimals = countDecimals(parameterDetails.max);
              let maxDecimalPlaces = Math.max(minDecimals, maxDecimals);

              if (parameterDetails?.parameter_id == 73 && connection_id == 74) {
                console.log("se cumplee esta parte", parameterDetails)
                const valueAsString = value.toString();
                if (valueAsString.length === 3) {

                  maxDecimalPlaces = 1
                } else if (valueAsString.length === 2) {
                  maxDecimalPlaces = 0
                }
              }

              const divisor = Math.pow(10, maxDecimalPlaces);

              const normalizeValue = (value: any) => {
                return value && value.toString().replace(',', '.');
              };
              let normalizedUpdateValue = normalizeValue(value);
              let adjustedValue = parseFloat(normalizedUpdateValue) / divisor;


    
                if (parameterDetails.bit_parameter_id || parameterDetails.bit_parameter_id == 0) {
                    adjustedValue = (adjustedValue >> parameterDetails.bit_parameter_id) & 1;
                }
    
                if (mainVariable.max_scale) {
                    adjustedValue = (adjustedValue * mainVariable.max_scale) / 100;
                }
    
                mainVariable.value = adjustedValue.toFixed(maxDecimalPlaces);
            } else {
                mainVariable.value = value;
            }
        });
    }
    

        if (state.dashBoardInfo[zoneId] && state.dashBoardInfo[zoneId].graph_favorites) {
          const graphFavorite = state.dashBoardInfo[zoneId].graph_favorites.find((gf: any) => {
            return gf.parameter_id == parameter_id &&
              gf.bit_parameter_id == bit_parameter_id &&
              ((gf.idmap_id == idmap_id) || (gf.idmap_id === null && idmap_id === "") || (gf.idmap_id === "" && idmap_id === null)) &&
              gf.connection_id == connection_id;
          });
          if (graphFavorite) {

            graphFavorite.value = value;

          }
        }

        if (state.dashBoardInfo[zoneId] && state.dashBoardInfo[zoneId].status) {
          const status = state.dashBoardInfo[zoneId].status[connection_id];
          if (status) {
            ['run', 'ready', 'fault', 'warning'].forEach((statusType) => {
              const statusRegister = status[statusType];
              if (statusRegister &&
                statusRegister.parameter_id == parameter_id &&
                ((statusRegister.idmap_id == idmap_id) || (statusRegister.idmap_id === null && idmap_id === "") || (statusRegister.idmap_id === "" && idmap_id === null))
              ) {
                const bitIndex = statusRegister.bit_parameter_id;
                statusRegister.value = (value >> bitIndex) & 1;
              }
            });
          }
        }

        if (state.dashBoardInfo[zoneId] && state.dashBoardInfo[zoneId].ip_registers) {
          Object.keys(state.dashBoardInfo[zoneId].ip_registers).forEach((ip) => {
            const register = state.dashBoardInfo[zoneId].ip_registers[ip].find((reg: any) => {
              return reg.parameter_id == parameter_id &&
                reg.bit_parameter_id == bit_parameter_id &&
                ((reg.idmap_id == idmap_id) || (reg.idmap_id === null && idmap_id === "") || (reg.idmap_id === "" && idmap_id === null)) &&
                reg.connection_id == connection_id;
            });
            if (register) {
              register.value = value;
            }
          });
        }
      });
    },

    reorderMainVariablesInDashboardInfo: (state, action) => {
      const { reorderedItems, zoneId } = action.payload;

      if (state.dashBoardInfo[zoneId].main_variables) {
     
        state.dashBoardInfo[zoneId].main_variables = state.dashBoardInfo[zoneId].main_variables.map((existingVariable: any) => {
          
          const newPosition = reorderedItems.indexOf(existingVariable.id);

          if (newPosition !== -1) {
            return {
              ...existingVariable,
              position: newPosition + 1,
            };
          }
          return existingVariable; 
        });

        state.dashBoardInfo[zoneId].main_variables.sort((a: any, b: any) => a.position - b.position);
      }
    },


  },

  extraReducers: (builder) => {

    builder.addCase(getFavoritesByConnectionId.fulfilled, (state, action: any) => {

      const newFavorites = action.payload;

      Object.keys(newFavorites).forEach((key) => {
        if (!state.parameters || !state.parameters[key]) {
          state.favorites[key] = newFavorites[key];
        }
      });
    });

    builder.addCase(getFavoritesByConnectionId.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(createFavorite.fulfilled, (state: any, action: any) => {
      const { connection_id, ...favoriteData } = action.payload;

      if (!Array.isArray(state.favorites[connection_id])) {
        state.favorites[connection_id] = [];
      }


      state.favorites[connection_id].push(favoriteData);
    });

    builder.addCase(createFavorite.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateFavorite.fulfilled, (state: any, action: any) => {

      const updatedFavorite = action.payload;
      const linkedFavoritesIds = state.linkedFavorites.map((linkedFavorite: any) => linkedFavorite.id);
      const connectionId = updatedFavorite.connection_id;

      if (state.favorites[connectionId]) {
        state.favorites[connectionId] = state.favorites[connectionId].map((favorite: any) => {



          if (favorite.id === updatedFavorite.id) {

            return {
              ...favorite,
              name: updatedFavorite.name !== undefined ? updatedFavorite.name : favorite.name,
              min_scale: updatedFavorite.min_scale !== undefined ? updatedFavorite.min_scale : favorite.min_scale,
              max_scale: updatedFavorite.max_scale !== undefined ? updatedFavorite.max_scale : favorite.max_scale,
              register: updatedFavorite.register !== undefined ? updatedFavorite.register : favorite.register,
              in_graph: updatedFavorite.in_graph !== undefined ? updatedFavorite.in_graph : favorite.in_graph,
              principal: updatedFavorite.principal !== undefined ? updatedFavorite.principal : favorite.principal,
              unit: updatedFavorite.unit !== undefined ? updatedFavorite.unit : favorite.unit,
              color: updatedFavorite.color !== undefined ? updatedFavorite.color : favorite.color,
              decimals: updatedFavorite.decimals !== undefined ? updatedFavorite.decimals : favorite.decimals,
            };
          }

          if (linkedFavoritesIds.includes(favorite.id)) {

            return {
              ...favorite,
              color: updatedFavorite.color
            }
          }

          return favorite;
        });
      }
    });

    builder.addCase(updateFavorite.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteFavorite.fulfilled, (state: any, action: any) => {
      const { connectionId, favoriteId } = action.payload;
      /*  console.log("favoriteid parmetrization", action.payload) */
      const linkedFavoritesIds = state.linkedFavorites.map((linkedFavorite: any) => linkedFavorite.id);
      /* console.log("linkedFavoritesIDs deleted", linkedFavoritesIds) */
      // Filtrar los favoritos, eliminando el que coincida con favoriteId
      const updatedFavorites = state.favorites[connectionId].filter(
        (favorite: any) => favorite.id !== favoriteId
      );

      // Si el array resultante está vacío, asegúrate de mantener la clave con un array vacío
      if (updatedFavorites.length === 0) {
        state.favorites[connectionId] = [];
      } else {
        state.favorites[connectionId] = updatedFavorites;
      }

      // Actualizar el color de los favoritos vinculados
      state.favorites[connectionId] = state.favorites[connectionId].map((favorite: any) => {
        if (linkedFavoritesIds.includes(favorite.id)) {

          return {
            ...favorite,
            color: null
          }
        }
        return favorite;
      });
    });

    builder.addCase(deleteFavorite.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(reorderFavorites.fulfilled, (state: any, action: any) => {
      const { favorites, connectionId } = action.payload;

      // Crear un nuevo array de favoritos reordenados y con la posición actualizada
      const reorderedFavorites = state.favorites[connectionId].map((existingFavorite: any) => {
        const favorite = favorites.find((f: any) => f.id === existingFavorite.id);
        if (favorite) {
          return { ...existingFavorite, position: favorite.position }; // Actualizar la posición
        }
        return existingFavorite; // Devolver el favorito tal cual si no coincide
      });

      // Asignar el array reordenado al estado
      state.favorites[connectionId] = reorderedFavorites;
    });


    builder.addCase(reorderFavorites.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getDashboardInfo.fulfilled, (state, action) => {
      const { zoneId, data } : any = action.payload;
    
      if (data && Object.keys(data).length !== 0) {
        if (!state.dashBoardInfo[zoneId]) {
          state.dashBoardInfo[zoneId] = data;
        } else {
          const existingData = state.dashBoardInfo[zoneId];
    
          const updatedData = Object.keys(data).reduce((acc : any, key : any) => {
            if (key === 'favorites' && Array.isArray(data[key])) {
              acc[key] = data[key].map((fav : any) => {
                const existingFav = existingData[key].find((eFav : any) => eFav.id === fav.id);
                return existingFav ? { ...existingFav, ...fav, value: existingFav.value } : fav;
              });
            } else if (key === 'main_variables' && Array.isArray(data[key])) {
              acc[key] = data[key].map((varItem : any) => {
                const existingVar = existingData[key].find((eVar : any) => eVar.id === varItem.id);
                return existingVar ? { ...existingVar, ...varItem, value: existingVar.value } : varItem;
              });
            } else if (key === 'status' && typeof data[key] === 'object') {
              acc[key] = Object.entries(data[key]).reduce((statusAcc : any, [connId, statusVal] : any) => {
                const existingStatus = existingData[key] && existingData[key][connId];
                statusAcc[connId] = { ...existingStatus, ...statusVal };
                return statusAcc;
              }, {});
            } else {
              acc[key] = data[key];
            }
            return acc;
          }, {});
    
          state.dashBoardInfo[zoneId] = updatedData;
        }
      } else {
        state.dashBoardInfo[zoneId] = {};
      }
    });
    

    builder.addCase(getDashboardInfo.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getLinkedFavorites.fulfilled, (state, action) => {

      state.linkedFavorites = action.payload;
    });

    builder.addCase(getLinkedFavorites.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createLinkedParameter.fulfilled, (state, action) => {

      const { favorite_visualization_id, favorite_parametrization_id, connection_id } = action.payload;



      const visualizationFavorite = state.favorites[connection_id].find((fav: any) => fav.id == favorite_visualization_id);


      if (visualizationFavorite) {

        const parametrizationFavorite = state.favorites[connection_id].find((fav: any) => fav.id == favorite_parametrization_id);


        if (parametrizationFavorite) {
          parametrizationFavorite.color = visualizationFavorite.color;
          state.linkedFavorites.push(parametrizationFavorite)
          state.linkedFavoritesByConnection.push(parametrizationFavorite.parameter_id)
        }
      }
    });


    builder.addCase(createLinkedParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getLinkedVariablesByConnection.fulfilled, (state, action) => {
      state.linkedFavoritesByConnection = action.payload;
    });

    builder.addCase(getLinkedVariablesByConnection.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteLinkedParameter.fulfilled, (state, action) => {
      // Eliminar el parameter_id del array linkedFavoritesByConnection
      const favorite = action.payload;

      state.linkedFavoritesByConnection = state.linkedFavoritesByConnection.filter(
        (parameter_id: any) => parameter_id !== favorite.parameter_id
      );

      state.linkedFavorites = state.linkedFavorites.filter(
        (linkedFavorite: any) => linkedFavorite.id !== favorite.id
      );

      // Buscar y actualizar el color a null en state.favorites
      Object.keys(state.favorites).forEach(connectionId => {
        state.favorites[favorite.connection_id] = state.favorites[favorite.connection_id].map((fav: any) => {
          if (fav.id === favorite.id) {
            return { ...fav, color: null };
          }
          return fav;
        });
      });
    });

    builder.addCase(deleteLinkedParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(resetFavoriteSelected.fulfilled, (state) => {
      state.favoriteIdSelected = null;
    });



  },
});
export const { setFilteredFavorite, setSelectedFavoriteId, updateDashboardValues, setDashBoardInfo, reorderMainVariablesInDashboardInfo, setFetchingFavorites } = parameterValuesSlice.actions;
export default parameterValuesSlice.reducer;