import React, { useState, useEffect, useContext, useRef } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import GeneralData from 'pages/Dashboard/GeneralData';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { SocketContext } from 'context/SocketProvider';
import _ from 'lodash'; // Para comparaciones profundas
import { setValuesOfMultiplesIps, updateCalculatedParameters } from 'slices/calculatedParameters/reducer';
import { updateStatusZoneValues } from 'slices/statusZone/reducer';
import { useParams } from 'react-router-dom';
import InstallationGeneralData from './InstallationGeneralData';

const InstallationDashboard: React.FC = () => {
  const [installationName, setInstallationName] = useState<string>('Nombre de la instalación');
  const [zones, setZones] = useState<any[]>([]);
  const [shouldEmit, setShouldEmit] = useState<boolean>(true); // Flag para controlar la emisión
  const dispatch: any = useDispatch();
  const id = useParams<{ id: string }>().id;
  const socket = useContext(SocketContext);

  const selectInstallationsState = (state: any) => state.Installations;
  const selectCalculatedParametersState = (state: any) => state.CalculatedParameters;
  const selectParametersBackupState = (state: any) => state.ParametersBackup;
  const selectFavoritesState = (state: any) => state.Favorites;
  const selectStatusZoneState = (state: any) => state.StatusZone;

  const installationDataProperties = createSelector(
    selectInstallationsState,
    (state) => state
  );

  const calculatedParametersProperties = createSelector(
    selectCalculatedParametersState,
    (state) => state
  );

  const parametersBackupProperties = createSelector(
    selectParametersBackupState,
    (state) => state
  );

  const favoritesProperties = createSelector(
    selectFavoritesState,
    (state) => state
  );

  const statusZoneProperties = createSelector(
    selectStatusZoneState,
    (state) => state
  );



  const { installations } = useSelector(installationDataProperties);
  const { calculatedParameters } = useSelector(calculatedParametersProperties);
  const { parametersBackup } = useSelector(parametersBackupProperties);
  const { favorites } = useSelector(favoritesProperties);
  const { statusZone } = useSelector(statusZoneProperties);





  useEffect(() => {
    const handleModbusValues = (data: any) => {
      // Assume `data` has the new values from Modbus connections

      // Dispatch actions to update Redux store
      dispatch(setValuesOfMultiplesIps(data && data.values));

      // Update calculated parameters and status zone values based on the new data
      dispatch(updateCalculatedParameters({
        parametersBackup: parametersBackup,
        favorites: favorites,
        data: data
      }));

      /*  dispatch(updateStatusZoneValues({
         parametersBackup: parametersBackup,
         data: data
       })); */
    };

    if (socket) {
      socket.on('send_modbus_values_multiples_ips', handleModbusValues);
    }

    return () => {
      if (socket) {
        socket.off('send_modbus_values_multiples_ips', handleModbusValues);
      }
    };
  }, [socket, dispatch, parametersBackup, favorites, calculatedParameters]);

  useEffect(() => {
    if (Array.isArray(installations) && installations.length > 0) {
      const activeInstallation = installations.find(installation =>
        installation.is_active && installation.id === Number(id));

      if (activeInstallation) {
        setInstallationName(activeInstallation.installation_name);
        setZones(activeInstallation.zones);  // Load the zones from the active installation
      }
    }
  }, [installations, id]);
  // Función para eliminar los campos `value` de los calculatedParameters
  const removeValueFields = (params: any) => {
    return Object.keys(params).reduce((acc: any, zoneId) => {
      acc[zoneId] = params[zoneId].map((param: any) => {
        const { value_1, value_2, value_3, value_4, value_5, ...rest } = param; // Excluimos los campos de valor
        return rest;
      });
      return acc;
    }, {});
  };

  useEffect(() => {
    // Esta función comparará el estado actual de calculatedParameters sin `value` con la versión anterior
    const currentParamsWithoutValues = removeValueFields(calculatedParameters);

    // Solo se ejecuta si debe emitirse y hay socket disponible
    if (shouldEmit && socket && calculatedParameters) {
      const parametersByIp: any = {};

      // Recorremos todas las zonas de los parámetros calculados
      Object.keys(calculatedParameters).forEach((zoneId) => {
        const zoneParameters = calculatedParameters[zoneId];
        zoneParameters.forEach((calcParam: any) => {
          for (let i = 1; i <= 5; i++) {
            const typeKey = `type_${i}`;
            const connectionIdKey = `connection_id_${i}`;
            const valueKey = `value_${i}`;
            const connectionId = calcParam[connectionIdKey];
            const parameterValue = calcParam[valueKey];

            if (connectionId && parameterValue) {
              const ipAddress = parametersBackup[connectionId]?.connection_ip;

              // Dependiendo del tipo de valor, gestionamos el dato de manera distinta
              if (calcParam[typeKey] === 'Parameter') {
                const parameter = parametersBackup[connectionId]?.parameters?.find((param: any) => Number(param.id) == Number(parameterValue));

                if (ipAddress && parameter) {
                  if (!parametersByIp[ipAddress]) {
                    parametersByIp[ipAddress] = [];
                  }
                  parametersByIp[ipAddress].push({
                    bit_parameter_id: parameter.bit_parameter_id || null,
                    connection_id: connectionId,
                    idmap_id: parameter.idmap_id || null,
                    is_active: true,
                    max: parameter.max || 'N/A',
                    max_scale: parameter.max_scale || null,
                    min: parameter.min || 'N/A',
                    min_scale: parameter.min_scale || null,
                    parameter_id: Number(parameter.parameter_id),
                    unit: parameter.unit || null,
                    value: parameter.value || '',
                  });
                }
              } else if (calcParam[typeKey] === 'Favorite') {
                const favorite = favorites[connectionId]?.find((fav: any) => fav.id === Number(parameterValue));

                if (ipAddress && favorite) {
                  const parameter = parametersBackup[connectionId]?.parameters?.find((param: any) => param.id == favorite.parameter_id);
                  if (parameter) {
                    if (!parametersByIp[ipAddress]) {
                      parametersByIp[ipAddress] = [];
                    }

                    parametersByIp[ipAddress].push({
                      bit_parameter_id: parameter.bit_parameter_id || null,
                      connection_id: connectionId,
                      idmap_id: parameter.idmap_id || null,
                      is_active: true,
                      max: parameter.max || 'N/A',
                      max_scale: favorite.max_scale !== undefined && favorite.max_scale !== null ? Number(favorite.max_scale) : null,
                      min: parameter.min || 'N/A',
                      min_scale: favorite.min_scale !== undefined && favorite.min_scale !== null ? favorite.min_scale : null,
                      parameter_id: parameter.parameter_id,
                      unit: favorite.unit || null,
                      value: '',
                    });
                  }
                }
              }
            }
          }
        });
      });

      Object.keys(statusZone).forEach((zoneId) => {
        const zoneStatus = statusZone[zoneId];
        Object.keys(zoneStatus).forEach((statusKey) => {
          const status = zoneStatus[statusKey];

          const { parameter_id, connection_id } = status;

          const ipAddress = parametersBackup[connection_id]?.connection_ip;
          const parameter = parametersBackup[connection_id]?.status?.find((param: any) => param.id == parameter_id);

          if (ipAddress && parameter) {
            if (!parametersByIp[ipAddress]) {
              parametersByIp[ipAddress] = [];
            }

            parametersByIp[ipAddress].push({
              bit_parameter_id: parameter.bit_parameter_id || null,
              connection_id,
              idmap_id: parameter.idmap_id || null,
              is_active: true,
              max: parameter.max || 'N/A',
              max_scale: parameter.max_scale || null,
              min: parameter.min || 'N/A',
              min_scale: parameter.min_scale || null,
              parameter_id: parameter.parameter_id,
              unit: parameter.unit || null,
              value: status.value || '',
            });
          }
        });
      });


      if (Object.keys(parametersByIp).length > 0) {
        const authUserString = sessionStorage.getItem('authUser');
        if (authUserString) {
          const authUser = JSON.parse(authUserString);
          /*  socket.emit('start_modbus_values_multiples_ips', {
             ip_registers: parametersByIp,
             token: authUser?.token,
           }); */

          // Una vez emitido, desactivamos la emisión
          setShouldEmit(false);
        }
      }
    }
  }, [calculatedParameters, socket, shouldEmit, parametersBackup, favorites]);

  const prevParamsWithoutValues = useRef();

  useEffect(() => {
    const currentParamsWithoutValues = removeValueFields(calculatedParameters);

    if (prevParamsWithoutValues.current && !_.isEqual(prevParamsWithoutValues.current, currentParamsWithoutValues)) {
      setShouldEmit(true); // Se reinicia la flag si cambian los parámetros (excepto los valores)
    }

    prevParamsWithoutValues.current = currentParamsWithoutValues;
  }, [calculatedParameters]);

  useEffect(() => {
    if (Array.isArray(installations) && installations.length > 0) {
      const activeInstallation = installations.find(installation =>
        installation.is_active && installation.id === id);



      if (activeInstallation) {
        setInstallationName(activeInstallation.installation_name);
        setZones(activeInstallation.zones);
      }
    }
  }, [installations]);

  return (
    <React.Fragment>
      <BreadCrumb
        pageTitle={installationName}
        subtitle={`Dashboard`}
      />
      <div className="page-content">
        <Container fluid>
          <Row className='justify-content-center '>
            <InstallationGeneralData registers={[]} zoneId={0} zoneName={''} />
            <Col lg={12} className="order-xxl-0">

              {zones.map((zone: any) => (
                <div key={zone.id} className="mb-3">
                  <GeneralData zoneName={zone.zone_name} zoneId={zone.id} disableEmittingValues={true} />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InstallationDashboard;
