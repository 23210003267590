import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getFavoritesByConnectionId as getFavoriteByConnectionApi,
  createFavorite as createFavoriteApi,
  updateFavorite as updateFavoriteApi,
  deleteFavorite as deleteFavoriteApi,
  isAnalogicUsed as isAnalogicUsedApi,
  reorderFavorites as reorderFavoritesApi,
  getDashboardInfo as getDashboardInfoApi,
  getLinkedFavorites as getLinkedFavoritesApi,
  createLinkedParameter as createLinkedParameterApi,
  deleteLinkedParameter as deleteLinkedParameterApi,
  getLinkedVariablesByConnection as getLinkedVariablesByConnectionApi,
  checkAnalogicInFavorites as checkAnalogicInFavoritesApi

} from "../../helpers/fakebackend_helper";


export const getFavoritesByConnectionId = createAsyncThunk("favorite/getFavoritesByConnectionId", async (connectionId: number) => {
  try {

    const response = await getFavoriteByConnectionApi(connectionId);

    return response;
  } catch (error) {
    return error;
  }
});

export const createFavorite = createAsyncThunk("favorite/createFavorite", async (favorite: any) => {
  try {
    const response: any = await createFavoriteApi(favorite);

   
    return response;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateFavorite = createAsyncThunk("favorite/updateFavorite", async (favoriteInfo: any) => {
  try {
    const response = await updateFavoriteApi(favoriteInfo);
    return favoriteInfo;
  } catch (error) {
    return error;
  }
});

export const deleteFavorite = createAsyncThunk("favorite/deleteFavorite", async (favorite: any) => {
  try {

    /*   console.log("argumento thunk delete", favorite) */
    const response = await deleteFavoriteApi(favorite.favoriteId);
    /*     console.log("response thunk delete", response) */
    return favorite;
  } catch (error) {
    return error;
  }
});



export const isAnalogicUsed = createAsyncThunk(
  "favorite/isAnalogicUsed",
  async ({ ip, parameter_id, device }: { ip: string; parameter_id: number; device: string }, { rejectWithValue }) => {

    const validVaconParameterIds = [59, 60, 61, 62, 75, 76];
    try {
      if (device !== 'Vacon' || !validVaconParameterIds.includes(parameter_id)) {
        return null;
      }

      const response = await isAnalogicUsedApi(ip, parameter_id, device);

      return response;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

export const reorderFavorites = createAsyncThunk("favorite/reorderFavorites", async (favorites: any) => {

  try {
    const response = await reorderFavoritesApi(favorites.favorites);

    return favorites;
  } catch (error) {
    return error;
  }
});


export const getDashboardInfo = createAsyncThunk(
  "favorite/getDashboardInfo",
  async (zoneId: number, { rejectWithValue }) => {
    try {

      const response = await getDashboardInfoApi(zoneId);

      return { data: response, zoneId: zoneId };
    } catch (error: any) {
      
      console.error("Failed to fetch dashboard info", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLinkedFavorites = createAsyncThunk("favorite/getLinkedFavorites", async (favoriteId: number) => {
  try {

    const response = await getLinkedFavoritesApi(favoriteId);

    return response;
  } catch (error) {
    return error;
  }
});


export const createLinkedParameter = createAsyncThunk("favorite/createLinkedParameter", async (linkedParameter: any) => {
  const response = await createLinkedParameterApi(linkedParameter);
  return linkedParameter;

});


export const deleteLinkedParameter = createAsyncThunk(
  "favorite/deleteLinkedParameter",
  async (favorite: any, { rejectWithValue }) => {
    try {
      const response = await deleteLinkedParameterApi(favorite.id);
      return favorite;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getLinkedVariablesByConnection = createAsyncThunk("favorite/getLinkedVariablesByConnection", async (connectionId: number) => {
  try {
    const response = await getLinkedVariablesByConnectionApi(connectionId);
    return response;
  } catch (error) {
    return error;
  }
});

export const checkAnalogicInFavorites = createAsyncThunk(
  "favorite/checkAnalogicInFavorites",
  async (analogicInfo: any) => {
    try {
      const response = await checkAnalogicInFavoritesApi(analogicInfo);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const resetFavoriteSelected = createAsyncThunk(
  "favorite/resetFavoriteSelected",
  async () => {
    try {
      return null;
    } catch (error) {
      return error;
    }
  }
);


