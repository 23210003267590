import { createSlice } from "@reduxjs/toolkit";
import { getSectionDataConfigByZone } from "./thunk";
import { config } from "process";

export const initialState: any = {
  sectionDataConfig: {},
  error: null,
  loading: false,
};

const sectionDataConfigSlice = createSlice({
  name: "sectionDataConfig",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getSectionDataConfigByZone.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSectionDataConfigByZone.fulfilled, (state, action) => {
      state.loading = false;
      const { zoneId, data } : any = action.payload; 
      state.sectionDataConfig[zoneId] = data;
    });
    builder.addCase(getSectionDataConfigByZone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload?.error || "Failed to fetch Synoptic Image Configurations";
    });
  },
});

export default sectionDataConfigSlice.reducer;
