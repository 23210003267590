import React, { useCallback, useContext, useEffect, useState } from "react";
import vaconImage from '../../../assets/images/vacon-100.png';
import { Link, useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormFeedback,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList as onGetCategoryList, addcategoryList as onAddCategoryList } from "slices/thunks";
import { useFormik } from "formik";
import * as Yup from "yup";
import Widgets1 from "pages/Dashboard/Devices";
import Widgets from "pages/Dashboard/GeneralData";
import { backupParametersByConnection, getParametersBackup, /* getParametersBackup */ } from 'slices/parametersBackup/thunk';
import { getParameterValuesByConnection } from 'slices/parameterValues/thunk';
import { SocketContext } from "context/SocketProvider";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirm from "Components/Common/Modals/ModalConfirm";
import { setParametersBackup } from "slices/parametersBackup/reducer";
import { set } from "lodash";
import rolesIds from "constants/roles";

const Device = () => {
    const { id } = useParams();
    const dispatch: any = useDispatch();
    const connectionID = Number(id);
    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const selectInstallationsState = (state: any) => state.Installations;

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );


    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state) => state
    );

    const profileUserData = createSelector(
        (state: any) => state.Profile.user,
        (user) => user
    );

    const user = useSelector(profileUserData);

    const { parametersBackup } = useSelector(parametersBackupProperties);
    const { parameterValues } = useSelector(parameterValuesProperties);
    const { installations } = useSelector(installationDataProperties);

    const [isBackupDone, setIsBackupDone] = useState<boolean>(true);
    const [isBackingUp, setIsBackingUp] = useState<boolean>(false);
    const [isSearchingBackup, setIsSearchingBackup] = useState<boolean>(true);
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [backupType, setBackupType] = useState('unsecured'); // default backup type
    const [isFetchingParameters, setIsFetchingParameters] = useState<boolean>(false);
    const socket = useContext(SocketContext);



    useEffect(() => {
        if (socket) {
            socket.on('BackupResponse', (data) => {
                if (data.success) {
                    toast.success(data.message)
                    setIsFetchingParameters(true);
                    if ((!parametersBackup || !parametersBackup[Number(id)] || parametersBackup[Number(id)].parameters.length == 0) && parameterValues[Number(id)]) {
                        dispatch(getParametersBackup({ connectionId: Number(id), parameterValues: parameterValues[Number(id)] }));
                    }
                } else {
                    toast.error(data.message)
                    setIsBackingUp(false);
                }
            });
        }

        return () => {
            if (socket) {
                socket.off('BackupResponse');
            }
        };
    }, [socket, dispatch, id, parameterValues, parametersBackup]);

    useEffect(() => {

        if (parametersBackup[connectionID] && parametersBackup[connectionID].parameters.length > 0) {

            setIsBackingUp(false);
            setIsFetchingParameters(false);
            setIsSearchingBackup(false)

        } else {
            setIsFetchingParameters(true);
        }
    }, [parametersBackup[connectionID]]);

    /*  useEffect(() => {
 
         if (!parameterValues || !parameterValues[Number(id)]) {
 
             dispatch(getParameterValuesByConnection(Number(id)));
         }
 
         if ((!parametersBackup || !parametersBackup[Number(id)]) && parameterValues[Number(id)] && !isBackingUp) {
 
             dispatch(getParametersBackup({ connectionId: Number(id), parameterValues: parameterValues[Number(id)] }));
         }
 
         if ((parametersBackup[Number(id)] && parametersBackup[Number(id)].parameters.length === 0) || (!parametersBackup[Number(id)])) {
 
             setIsBackupDone(false);
         } else {
 
             setIsBackupDone(true);
         }
 
         if (parametersBackup[Number(id)]) {
             setIsSearchingBackup(false);
         }
     }, [dispatch, id, parametersBackup, parameterValues, isBackingUp, isFetchingParameters]); */

    const profiledropdownData = createSelector(
        (state: any) => state.Jobs,
        (user) => ({
            categoryList: user.categoryList
        })
    );

    const { categoryList } = useSelector(profiledropdownData);
    const [categoryData, setCategoryData] = useState<any>([]);

    useEffect(() => {

        dispatch(onGetCategoryList());
    }, [dispatch]);

    useEffect(() => {
        setCategoryData(categoryList);
    }, [categoryList]);

    const [addvalue, setAddValue] = useState<any>(null);
    const [cateData, setCateData] = useState<boolean>(false);

    const modalToggle = useCallback(() => {
        if (cateData) {
            setCateData(false);
            setAddValue(null);
        } else {
            setCateData(true);
        }
    }, [cateData]);

    const handleButtonClicks = () => {
        validation.resetForm();
        setAddValue("");
        setCateData(!cateData);
        modalToggle();
    };

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            lable: (addvalue && addvalue.lable) || '',
            position: (addvalue && addvalue.position) || '',
            iconName: (addvalue && addvalue.iconName) || '',
        },
        validationSchema: Yup.object({
            lable: Yup.string().required("Please Enter Category Label."),
            position: Yup.string().required("Please Enter Positions"),
            iconName: Yup.string().required("Please Enter Icon")
        }),
        onSubmit: (values) => {
            const categorisedList = {
                id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                lable: values["lable"],
                position: values["position"],
                iconName: values["iconName"]
            };
            // save new Folder
            dispatch(onAddCategoryList(categorisedList));
            validation.resetForm();
            modalToggle();
        },
    });

    const handleSearch = (ele: any) => {
        let item = ele.value;

        if (item === "All Tasks") {
            setCategoryData([...categoryList]);
        } else {
            handleSearchData({ data: categoryList, item: item, setState: setCategoryData });
        }
    };

    const handleSearchData = ({ data, item, setState }: any) => {
        setState(
            data.filter((search: any) =>
                Object.values(search).some(
                    (field) =>
                        typeof field === 'string' &&
                        field.toLowerCase().includes(item?.toLowerCase()),
                )
            )
        );
    };

    const toggleModalConfirm = () => setModalConfirmOpen(!modalConfirmOpen);

    const handleBackup = (type: any) => {
        setBackupType(type);
        toggleModalConfirm();
    };

    const handleConfirmBackup = (idSelected: any) => {
        dispatch(setParametersBackup({ connection_id: Number(id), values: [] }));
        dispatch(backupParametersByConnection({ connection_id: Number(id), backup_type: backupType }));
        setIsBackingUp(true);
        setIsSearchingBackup(false); // Asegurar que el spinner de búsqueda no se muestre
    };

    document.title = "Dispositivos | ecentroserver";

    const installation = installations?.find((inst: any) =>
        inst.zones.some((zone: any) => zone.connections.some((conn: any) => conn.id === connectionID))
    );
    const zone = installation?.zones.find((zone: any) =>
        zone.connections.some((conn: any) => conn.id === connectionID)
    );
    const connection = zone?.connections.find((conn: any) => conn.id === connectionID);

    return (
        <React.Fragment>
            <BreadCrumb
                pageTitle={installation && installation.installation_name}
                pageTitleUrl={`/dashboard/instalacion/${installation.id}`}
                subtitle={zone && zone.zone_name}
                subtitleUrl={`/dashboard/zona/${zone && zone.id}`}

                subsubtitle={parametersBackup && parametersBackup[connectionID] ? parametersBackup[connectionID].connection_name : ''}
                subsubtitleUrl={`/dispositivo/${connectionID}`}

            />
            <div className="page-content">
                <Container fluid className="container-fluid">
                    <Row>
                        <Widgets />
                    </Row>

                    <Row className="d-flex align-items-center">
                        <Col lg={3} className="h-100 d-flex justify-content-center mb-3">
                            <div>
                                <img src={vaconImage} alt="vacon-100-flow" style={{ maxWidth: '100%', maxHeight: '100vh' }} />
                                {isBackupDone ?
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            disabled={user.role_id === rolesIds.operator}
                                            color="primary"
                                            className="m-1"
                                            onClick={() => handleBackup('unsecured')}>
                                            Inicia el Backup
                                        </Button>
                                        <Button
                                            color="primary"
                                            className="m-1"
                                            disabled={user.role_id === rolesIds.operator}
                                            onClick={() => handleBackup('secured')}>
                                            Inicia el Backup Seguro
                                        </Button>
                                    </div> : null
                                }
                            </div>
                        </Col>

                        <Col lg={9}>
                            {!parametersBackup[connectionID] ? (
                                <div className="d-flex flex-column align-items-center">
                                    <Spinner color="primary" />
                                    <p className="mt-2">
                                        {isBackingUp && !isFetchingParameters
                                            ? "El backup está en proceso, por favor espere unos minutos..."
                                            : isFetchingParameters
                                                ? "Obteniendo información..."
                                                : "Comprobando Backup..."}
                                    </p>
                                </div>
                            ) : parametersBackup[connectionID] && parametersBackup[connectionID].parameters.length > 0 ? (
                                <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1 g-3">
                                    {categoryData && categoryData.map((item: any, key: any) => (
                                        <Col key={key}>
                                            <Card className={item.disabled ? "bg-light " : "custom-container"}>
                                                <CardBody className="text-center py-4" style={{ minHeight: '160px' }}>
                                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                                        {!parametersBackup[connectionID] && item.lable === 'Parámetros' ? (
                                                            <Spinner color="primary" className="display-5 text-primary" />
                                                        ) : (
                                                            item.icon ? (
                                                                <i className={item.icon + " display-5 text-primary"} />
                                                            ) : null
                                                        )}
                                                    </div>
                                                    <Link to={`${item.url}/${id}`} className="stretched-link" style={!parametersBackup[connectionID] || item.disabled ? { pointerEvents: "none" } : {}}>
                                                        <h5 className="mt-4">{item.lable}</h5>
                                                    </Link>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                                    {!isBackingUp && (
                                        <p>No se ha hecho el backup de este dispositivo, para comenzar a usarlo tendrá que hacer el backup.</p>
                                    )}
                                    {isBackingUp ? (
                                        <div className="d-flex flex-column align-items-center">
                                            <Spinner color="primary" />
                                            <p className="mt-2">El backup está en proceso, por favor espere unos minutos...</p>
                                        </div>
                                    ) : (
                                        <Button color="primary" onClick={() => {

                                            dispatch(backupParametersByConnection({ connection_id: Number(id), backup_type: 'unsecured' }));
                                            setIsBackingUp(true);
                                            setIsSearchingBackup(false); // Asegurar que el spinner de búsqueda no se muestre
                                            // Lógica para iniciar el primer backup
                                            /*  dispatch(getParametersBackup({ connectionId: Number(id), parameterValues: parameterValues[Number(id)] })); */


                                        }}
                                            disabled={user.role_id === rolesIds.operator}
                                        >Inicia el Backup</Button>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>

                    <Modal className="fade zoomIn" isOpen={cateData} toggle={() => setCateData(!cateData)} id="createFolderModal" modalClassName="zoomIn" centered tabIndex={-1} >
                        <ModalHeader className="p-3 bg-primary-subtle" id="createFolderModalLabel" toggle={() => setCateData(!cateData)}> Add Category </ModalHeader>
                        <ModalBody>
                            <form autoComplete="off" className="needs-validation createfolder-form" id="createfolder-form" noValidate
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="mb-4">
                                    <label htmlFor="lable-input" className="form-lable">Icon</label>
                                    <Input type="text" className="form-control" id="icon-input"
                                        placeholder="ri-bar-chart-fill"
                                        name='iconName'
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.iconName || ""}
                                        invalid={validation.touched.iconName && validation.errors.iconName ? true : false}
                                    />
                                    {validation.touched.iconName && validation.errors.iconName ? (
                                        <FormFeedback type="invalid">{validation.errors.iconName}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="lable-input" className="form-lable">Category</label>
                                    <Input type="text" className="form-control" id="label-input"
                                        placeholder="Enter category Label"
                                        name='lable'
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.lable || ""}
                                        invalid={validation.touched.lable && validation.errors.lable ? true : false}
                                    />
                                    {validation.touched.lable && validation.errors.lable ? (
                                        <FormFeedback type="invalid">{validation.errors.lable}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="position-input" className="form-label">Position <span className="text-danger"> *(Enter Position in number)</span></label>
                                    <Input type="text" className="form-control" id="position-input"
                                        placeholder="Enter position"
                                        name='position'
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.position || ""}
                                        invalid={validation.touched.position && validation.errors.position ? true : false}
                                    />
                                    {validation.touched.position && validation.errors.position ? (
                                        <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-ghost-danger" onClick={() => setCateData(false)}><i className="ri-close-line align-bottom"></i> Close</button>
                                    <button type="submit" className="btn btn-primary" id="addNewFolder">Add</button>
                                </div>
                            </form>
                        </ModalBody>
                    </Modal >

                    <ModalConfirm
                        isOpen={modalConfirmOpen}
                        toggle={toggleModalConfirm}
                        title="Confirmar Backup"
                        message={`¿Está seguro de que quiere iniciar un ${backupType === 'secured' ? 'Backup Seguro' : 'Backup'}?`}
                        onConfirm={handleConfirmBackup}
                        onCancel={toggleModalConfirm}
                        iconName='ri-information-line display-5 text-warning'
                        idSelected={connectionID}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Device;
