import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useParams, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from 'context/SocketProvider';
import NoInstallations from 'pages/Initial/NoInstallations';
import NoZones from 'pages/Initial/NoZones';
import { getDashboardInfo } from 'slices/favorites/thunk';
import { updateDashboardValues } from 'slices/favorites/reducer';
import horizontalSynoptic from "../../assets/images/horizontal_synoptic.png";
import verticalSynoptic from "../../assets/images/vertical_synoptic.png";
import processImage from "../../assets/images/process_2.png";
import productImage from "../../assets/images/product.png";
import * as Yup from 'yup';
import Select from 'react-select';
import rolesIds from 'constants/roles';
import Empty from 'pages/Initial/Empty';
import { toast } from 'react-toastify';
import { updateCalculatedParameters } from 'slices/calculatedParameters/reducer';
import GeneralData from 'pages/Dashboard/GeneralData';
import { Formik, Field, Form, FieldProps, ErrorMessage } from 'formik';
import SynopticCard from './SynopticCard';
import { getSynopticImageConfigByZone } from 'slices/synopticImageConfig/thunk';
import StatusIndicator from './StatusIndicator';
import { resetActiveFaults } from 'slices/faultHistory/reducer';
import { resetActiveAlarms } from 'slices/alarmHistory/reducer';



const Synoptic: React.FC = () => {
  document.title = "DashBoard | Ecentroserver";
  const dispatch: any = useDispatch();
  const { id } = useParams<{ id: string }>();

  const systemOptions = [
    { value: 'desalinationPlant', label: 'Planta desaladora' }
  ];

  const synopticOptions = [
    { value: 'pretreatment', label: 'Pretratamiento' },
    { value: 'pretreatmentAndProcess', label: 'Pretratamiento y proceso' },
    { value: 'productAndBrine', label: 'Producto y salmuera' },
  ];

  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const [hasEmitted, setHasEmitted] = useState(false);
  const [connections, setConnections] = useState<any[]>([]);
  const [isSynopticOpen, setIsSynopticOpen] = useState(false);
  const [initialSynopticImageConfigFormValues, setInitialFormSynopticConfigFormValues] = useState<any>({});
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const selectStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderRadius: '20px',
      borderColor: state.isFocused ? '#80bdff' : base.borderColor,
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#80bdff' : '#ced4da'
      }
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: '10px<'
    }),
  };

  const selectFavoritesState = (state: any) => state.Favorites;
  const selectInstallationsState = (state: any) => state.Installations;
  const selectParametersBackupState = (state: any) => state.ParametersBackup;
  const selectSynopticImageConfigState = (state: any) => state.SynopticImageConfig;

  const profiledropdownData = createSelector(
    (state: any) => state.Profile.user,
    (user) => user
  );

  const installationDataProperties = createSelector(
    selectInstallationsState,
    (state) => state
  );

  const FavoritesProperties = createSelector(
    selectFavoritesState,
    (state) => state
  );

  const parametersBackupProperties = createSelector(
    selectParametersBackupState,
    (state) => state
  );

  const synopticImageConfigProperties = createSelector(
    selectSynopticImageConfigState,
    (state) => state
  );


  const { installations } = useSelector(installationDataProperties);
  const { parametersBackup } = useSelector(parametersBackupProperties);
  const { favorites } = useSelector(FavoritesProperties);
  const { synopticImageConfig } = useSelector(synopticImageConfigProperties);


  const dashBoardInfo = useSelector((state: any) => {
    return state.Favorites.dashBoardInfo[Number(id)] || {};
  });

  const synopticImageConfigByZone = synopticImageConfig[Number(id)];



  const user = useSelector(profiledropdownData);


  const toogleIsSynopticOpen = (synopticImageConfig: any) => {
    setIsSynopticOpen(!isSynopticOpen);
    setInitialFormSynopticConfigFormValues({
      systemType: synopticImageConfig.system_type || '',
      synopticType: synopticImageConfig.synoptic_type || '',
      wellPumpCount: synopticImageConfig.well_pump_count || 0,
      transferPumpCount: synopticImageConfig.transfer_pump_count || 0,
      sandFilter: synopticImageConfig.sand_filter || false,
      cartridgeFilter: synopticImageConfig.cartridge_filter || false,
      antiScaling: synopticImageConfig.antiscaling || false,
      flushing: synopticImageConfig.flushing || false,
      booster: synopticImageConfig.booster || false,
      isave: synopticImageConfig.isave || true,
      productPumpCount: synopticImageConfig.product_pump_count || 0,
      brinePumpCount: synopticImageConfig.brine_pump_count || 0,
    })
  }

  useEffect(() => {
    dispatch(getSynopticImageConfigByZone(Number(id)));
  }, [])



  useEffect(() => {
    if (id) {
      dispatch(getDashboardInfo(Number(id)));
    }
  }, [dispatch, id, installations]);

  useEffect(() => {
    if (installations && Array.isArray(installations)) {
      const matchedZones = installations.flatMap((installation: any) =>
        installation.zones.filter((zone: any) => zone.id == Number(id))
      );

      if (matchedZones && Array.isArray(matchedZones) && matchedZones.length > 0) {
        setConnections(matchedZones[0].connections);
      }
    }
  }, [installations, id]);

  useEffect(() => {
    if (connections && Array.isArray(connections) && connections.length > 0) {
      setHasEmitted(false);
    }
  }, [dashBoardInfo.status]);

  useEffect(() => {
    if (installations && Array.isArray(installations)) {
      const matchedInstallation = installations.find((installation: any) =>
        installation.zones.some((zone: any) => zone.id === Number(id))
      );

      if (!matchedInstallation) {
        const activeInstallation = installations.find((installation: any) => installation.is_active);
        if (activeInstallation) {
          navigate(`/dashboard/instalacion/${activeInstallation.id}`);
        }
      }
    }
  }, [installations, id, navigate]);


  useEffect(() => {
    const handleModbusValues = (data: any) => {
      const payload = {
        data: data,
        parametersBackup: parametersBackup,
        zoneId: Number(id)
      };

      if (parametersBackup.length > 0) {
        dispatch(updateDashboardValues(payload));
        dispatch(updateCalculatedParameters({ parametersBackup: parametersBackup, favorites: favorites, data: data }));
      }

    };

    if (socket) {
      socket.on('send_modbus_values_multiples_ips', handleModbusValues);
    }

    return () => {
      if (socket) {
        socket.off('send_modbus_values_multiples_ips', handleModbusValues);
      }
    };

  }, [socket, dashBoardInfo, dispatch, hasEmitted, parametersBackup, favorites]);

  useEffect(() => {
    if (socket) {
      socket.on('resetConfirmation', (data: any) => {
        toast.success(`Reset realizado para IPs`);
        dispatch(resetActiveFaults())
        dispatch(resetActiveAlarms())
      });

      socket.on('resetError', (data: any) => {
        toast.error(`Error al resetear las siguientes IPs: ${data.ips.join(', ')}`);
      });
    }

    return () => {
      if (socket) {
        socket.off('resetConfirmation');
        socket.off('resetError');
      }
    };
  }, [socket]);

  const PretreatmentForm: React.FC<{ synopticType: string | null }> = ({ synopticType }) => (
    <Card>
      <CardHeader className='fw-bold h5'>Pretratamiento</CardHeader>
      <CardBody>
        <FormGroup row>
          <Label for="wellPumpCount" sm={4}>
            {synopticType === 'pretreatmentAndProcess' ? 'Bomba de pozo' : 'Bombas de pozo'}
          </Label>
          <Col sm={8}>
            {synopticType === 'pretreatmentAndProcess' ? (
              <div className="form-check form-switch form-switch-lg">
                <Field name="wellPumpCount">
                  {({ field, form }: FieldProps) => (
                    <input
                      {...field}
                      type="checkbox"
                      className="form-check-input"
                      id="wellPumpCount"
                      checked={field.value === 1}
                      onChange={() => {
                        if (field.value === 1) {
                          form.setFieldValue(field.name, 0);
                        } else {
                          form.setFieldValue(field.name, 1);
                          form.setFieldValue('transferPumpCount', 0); // Desmarcar "Bomba de transferencia"
                        }
                      }}
                    />
                  )}
                </Field>
              </div>
            ) : (
              <Field name="wellPumpCount" className="rounded-pill" as={Input} type="number" />
            )}
            <ErrorMessage name="wellPumpCount" component="div" className="text-danger" />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="transferPumpCount" sm={4}>
            {synopticType === 'pretreatmentAndProcess' ? 'Bomba de transferencia' : 'Bombas de transferencia'}
          </Label>
          <Col sm={8}>
            {synopticType === 'pretreatmentAndProcess' ? (
              <div className="form-check form-switch form-switch-lg">
                <Field name="transferPumpCount">
                  {({ field, form }: FieldProps) => (
                    <input
                      {...field}
                      type="checkbox"
                      className="form-check-input"
                      id="transferPumpCount"
                      checked={field.value === 1}
                      onChange={() => {
                        if (field.value === 1) {
                          form.setFieldValue(field.name, 0);
                        } else {
                          form.setFieldValue(field.name, 1);
                          form.setFieldValue('wellPumpCount', 0); // Desmarcar "Bomba de pozo"
                        }
                      }}
                    />
                  )}
                </Field>
              </div>
            ) : (
              <Field name="transferPumpCount" className="rounded-pill" as={Input} type="number" />
            )}
            <ErrorMessage name="transferPumpCount" component="div" className="text-danger" />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>Filtro de arena</Label>
          <Col>
            <div className="form-check form-switch form-switch-lg">
              <Field name="sandFilter">
                {({ field, form }: FieldProps) => (
                  <input
                    {...field}
                    type="checkbox"
                    className="form-check-input"
                    id="sandFilter"
                    checked={!!field.value}
                    onChange={() => form.setFieldValue(field.name, !field.value)}
                  />
                )}
              </Field>
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>Filtro de cartucho</Label>
          <Col>
            <div className="form-check form-switch form-switch-lg">
              <Field name="cartridgeFilter">
                {({ field, form }: FieldProps) => (
                  <input
                    {...field}
                    type="checkbox"
                    className="form-check-input"
                    id="cartridgeFilter"
                    checked={!!field.value}
                    onChange={() => form.setFieldValue(field.name, !field.value)}
                  />
                )}
              </Field>
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>Dosificadora antiincrustante</Label>
          <Col>
            <div className="form-check form-switch form-switch-lg">
              <Field name="hasAntiScaling">
                {({ field, form }: FieldProps) => (
                  <input
                    {...field}
                    type="checkbox"
                    className="form-check-input"
                    id="hasAntiScaling"
                    checked={!!field.value}
                    onChange={() => form.setFieldValue(field.name, !field.value)}
                  />
                )}
              </Field>
            </div>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );


  const ProcessForm: React.FC = () => (
    <Card>
      <CardHeader className='fw-bold h5'>Proceso</CardHeader>
      <CardBody>
        {/* Booster Checkbox */}
        <FormGroup row>
          <Label sm={4}>Booster</Label>
          <Col sm={8}>
            <div className="form-check form-switch form-switch-lg">
              <Field name="booster">
                {({ field, form }: FieldProps) => (
                  <input
                    {...field}
                    type="checkbox"
                    className="form-check-input"
                    id="booster"
                    checked={!!field.value}
                    onChange={() => {
                      form.setFieldValue(field.name, !field.value); // Toggle Booster
                      if (!field.value) {
                        form.setFieldValue('isave', false); // Uncheck Isave
                      }
                    }}
                  />
                )}
              </Field>
            </div>
            <ErrorMessage name="booster" component="div" className="text-danger" />
          </Col>
        </FormGroup>

        {/* Isave Checkbox */}
        <FormGroup row>
          <Label sm={4}>Isave</Label>
          <Col sm={8}>
            <div className="form-check form-switch form-switch-lg">
              <Field name="isave">
                {({ field, form }: FieldProps) => (
                  <input
                    {...field}
                    type="checkbox"
                    className="form-check-input"
                    id="isave"
                    checked={!!field.value}
                    onChange={() => {
                      form.setFieldValue(field.name, !field.value); // Toggle Isave
                      if (!field.value) {
                        form.setFieldValue('booster', false); // Uncheck Booster
                      }
                    }}
                  />
                )}
              </Field>
            </div>
            <ErrorMessage name="isave" component="div" className="text-danger" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Flushing</Label>
          <Col sm={8}>
            <div className="form-check form-switch form-switch-lg">
              <Field name="flushing">
                {({ field, form }: FieldProps) => (
                  <input
                    {...field}
                    type="checkbox"
                    className="form-check-input"
                    id="flushing"
                    checked={!!field.value}
                    onChange={() => form.setFieldValue(field.name, !field.value)}
                  />
                )}
              </Field>
            </div>
            <ErrorMessage name="flushing" component="div" className="text-danger" />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );

  const ProductAndBrineForm: React.FC = () => (
    <Card>
      <CardHeader className='fw-bold h5'>Producto y Salmuera</CardHeader>
      <CardBody>
        <FormGroup row>
          <Label for="productPumpCount" sm={6}>Bombas de producto *</Label>
          <Col sm={6}>
            <Field name="productPumpCount" as={Input} type="number" className="rounded-pill" />
            <ErrorMessage name="productPumpCount" component="div" className="text-danger" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="brinePumpCount" sm={6}>Bombas de salmuera *</Label>
          <Col sm={6}>
            <Field name="brinePumpCount" as={Input} type="number" className="rounded-pill" />
            <ErrorMessage name="brinePumpCount" component="div" className="text-danger" />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );



  const validationSchema = Yup.object().shape({
    systemType: Yup.string().required('El tipo de sistema es obligatorio'),
    synopticType: Yup.string().required('El tipo de sinóptico es obligatorio'),
    wellPumpCount: Yup.number()
      .required('El número de bombas de pozo es obligatorio')
      .min(0, 'El número de bombas de pozo no puede ser negativo'),
    transferPumpCount: Yup.number()
      .required('El número de bombas de transferencia es obligatorio')
      .min(0, 'El número de bombas de transferencia no puede ser negativo'),
    productPumpCount: Yup.number()
      .required('El número de bombas de producto es obligatorio')
      .min(0, 'El número de bombas de producto no puede ser negativo'),
    brinePumpCount: Yup.number()
      .required('El número de bombas de salmuera es obligatorio')
      .min(0, 'El número de bombas de salmuera no puede ser negativo'),
  }).test(
    'pump-count-check',
    'La suma de bombas de pozo y bombas de transferencia no puede exceder 4',
    function (values) {
      const { wellPumpCount = 0, transferPumpCount = 0 } = values;
      return wellPumpCount + transferPumpCount <= 4;
    }
  ).test(
    'product-brine-pump-check',
    'La suma de bombas de producto y salmuera no puede exceder 4',
    function (values) {
      const { productPumpCount = 0, brinePumpCount = 0 } = values;
      return productPumpCount + brinePumpCount <= 4;
    }
  );


  const renderGroups = (values: any) => {
    switch (values.synopticType) {
      case 'pretreatment':
        return (
          <PretreatmentForm synopticType={values.synopticType} />
        );

      case 'pretreatmentAndProcess':
        return (
          <>
            <PretreatmentForm synopticType={values.synopticType} />
            <ProcessForm />
          </>
        );

      case 'productAndBrine':
        return (
          <>
            <ProductAndBrineForm />
          </>
        );

      default:
        return null;
    }
  };

  const resetValues = (setFieldValue: any) => {
    setFieldValue('wellPumpCount', 0);
    setFieldValue('transferPumpCount', 0);
    setFieldValue('sandFilter', false);
    setFieldValue('cartridgeFilter', false);
    setFieldValue('antiScaling', false);
    setFieldValue('flushing', false);
    setFieldValue('booster', false);
    setFieldValue('isave', false);
    setFieldValue('productPumpCount', 0);
    setFieldValue('brinePumpCount', 0);
  };


  const imageMappings = [
    {
      conditions: {
        system_type: 'desalinationPlant',
        synoptic_type: 'pretreatmentAndProcess',
        well_pump_count: 1,
        transfer_pump_count: 0,
        sand_filter: true,
        cartridge_filter: true,
        antiscaling: true,
        isave: true,
        flushing: true,
      },
      image: require('../../assets/images/process_2.png')
    }

  ];

  const getImageForConfig = (config: any) => {

    const mapping = imageMappings.find(({ conditions }) => {
      const comparison = {
        system_type: conditions.system_type === config.system_type,
        synoptic_type: conditions.synoptic_type === config.synoptic_type,
        well_pump_count: conditions.well_pump_count === config.well_pump_count,
        transfer_pump_count: conditions.transfer_pump_count === config.transfer_pump_count,
        sand_filter: conditions.sand_filter === config.sand_filter,
        cartridge_filter: conditions.cartridge_filter === config.cartridge_filter,
        antiscaling: conditions.antiscaling === config.antiscaling,
        isave: conditions.isave === config.isave,
        flushing: conditions.flushing === config.flushing,
      };

      return Object.values(comparison).every(Boolean); // Verifica si todas las propiedades coinciden
    });

    return mapping ? mapping.image : null;
  };
  /*   const synopticImage = synopticImageConfigByZone
      ? getImageForConfig(synopticImageConfigByZone)
      : null; */

  const synopticImage = Number(id) == 4 || Number(id) == 5 || Number(id) == 7 || Number(id) == 17 || Number(id) == 18 ? processImage : Number(id) == 19 ? productImage : ''



  return (
    <>
      {isPortrait && (
        <div style={{
          position: 'fixed',
          top: '9%',
          left: 0,
          height: '100vh',
          width: '100%',
          backgroundColor: 'white',
          color: 'black',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '20px'
        }}>
          <p>Por favor, gire su dispositivo para poder ver el sinóptico</p>
        </div>
      )}
      {Array.isArray(installations) && installations.filter((installation: any) => installation.is_active).length === 0 && user.role_id != rolesIds.operator &&
        user.role_id != rolesIds.technician ? (
        <NoInstallations />
      ) : Array.isArray(installations) && installations.every((installation: any) => installation.zones.length === 0 || installation.zones.every((zone: any) => !zone.is_active)) && user.role_id != rolesIds.operator &&
        user.role_id != rolesIds.technician ? (
        <NoZones />
      ) : Array.isArray(installations) && installations.every((installation: any) => installation.zones.length === 0 && installation.zones.every((zone: any) => !zone.is_active)) && (user.role_id == rolesIds.operator || user.role_id == rolesIds.technician) ?
        (
          <Empty />
        ) :
        (
          <React.Fragment>

            <BreadCrumb
              pageTitle={Array.isArray(installations) && installations
                .find((installation: any) => installation.zones
                  .some((zone: any) => zone.id === Number(id) && zone.is_active))?.installation_name}
              pageTitleUrl={Array.isArray(installations) && installations
                .find((installation: any) => installation.zones
                  .some((zone: any) => zone.id === Number(id) && zone.is_active))
                ? `/dashboard/instalacion/${installations.find((installation: any) =>
                  installation.zones.some((zone: any) => zone.id === Number(id) && zone.is_active))?.id}`
                : ''}
              subtitle={Array.isArray(installations) && installations
                .flatMap((installation: any) => installation.zones)
                .find((zone: any) => zone.id == Number(id) && zone.is_active)?.zone_name}
              subsubtitle={"Sinóptico"}
            />
            <div className="page-content">
              <Modal isOpen={isSynopticOpen} className='overflow-scroll'>
                <ModalHeader>Configuración del Sinóptico</ModalHeader>
                <Formik

                  initialValues={initialSynopticImageConfigFormValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    const { wellPumpCount, transferPumpCount, productPumpCount, brinePumpCount } = values;

                    if (wellPumpCount + transferPumpCount > 4) {
                      setErrors({
                        wellPumpCount: 'La suma entre las bombas de pozo y transferencia no puede superar 4',
                        transferPumpCount: 'La suma entre las bombas de pozo y transferencia no puede superar 4',
                      });
                      setSubmitting(false);
                      return;
                    }

                    if (productPumpCount + brinePumpCount > 4) {
                      setErrors({
                        productPumpCount: 'La suma entre las bombas de producto y salmuera no puede superar 4',
                        brinePumpCount: 'La suma entre las bombas de producto y salmuera no puede superar 4',
                      });
                      setSubmitting(false);
                      return;
                    }

                    toogleIsSynopticOpen(synopticImageConfig);


                    setSubmitting(false);
                  }}
                >
                  {({ setFieldValue, values }) => (
                    <Form>
                      <ModalBody>
                        <FormGroup row>
                          <Label for="systemType" sm={4}>Seleccione el tipo de sistema *</Label>
                          <Col sm={8}>
                            <Select
                              options={systemOptions}
                              styles={selectStyles}
                              onChange={(option: any) => {
                                setFieldValue('systemType', option.value);
                                resetValues(setFieldValue);
                                setFieldValue('synopticType', '');
                              }}
                              placeholder='Seleccione el tipo de sistema'
                              value={systemOptions.find(option => option.value === values.systemType)}

                            />
                            <ErrorMessage name="systemType" component="div" className="text-danger" />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="synopticType" sm={4}>Seleccione el tipo de sinóptico *</Label>
                          <Col sm={8}>
                            <Select
                              placeholder='Seleccione el tipo sinóptico'
                              options={synopticOptions}
                              styles={selectStyles}
                              onChange={(option: any) => {
                                setFieldValue('synopticType', option.value);
                                resetValues(setFieldValue);

                              }}
                              value={synopticOptions.find(option => option.value === values.synopticType)}
                              isDisabled={!values.systemType}
                            />
                            <ErrorMessage name="synopticType" component="div" className="text-danger" />
                          </Col>
                        </FormGroup>
                        {renderGroups(values)}
                      </ModalBody>
                      <ModalFooter>
                        <Button className='rounded-pill' color="secondary" onClick={() => { toogleIsSynopticOpen(synopticImageConfigByZone) }}>Cancelar</Button>
                        <Button className='rounded-pill' color="primary" type="submit">Guardar</Button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </Modal>
              <Row>
                <Col lg={12} className="order-xxl-0 order-first">
                  <GeneralData registers={dashBoardInfo.ip_registers} />
                </Col>
              </Row>
              <Container fluid className="synoptic-container">
                <div className="synoptic-wrapper">
                  <Button
                    color="primary"
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 10,
                      zIndex: 10,
                      borderRadius: "5px",
                    }}
                    onClick={() => { toogleIsSynopticOpen(synopticImageConfigByZone) }}
                  >
                    <i className="ri-settings-4-fill" />
                  </Button>

                  {
                    synopticImage && (

                      <img
                        className="d-md-block horizontal-synoptic"
                        src={synopticImage}
                        alt="Vista Sinóptica"
                      />
                    )}

                  <SynopticCard mainVariables={dashBoardInfo.main_variables} className={'card-water-feed'} maxItems={20} SynopticCardId={1} sectionId={1} />
                  {Number(id) !== 19 && <SynopticCard mainVariables={dashBoardInfo.main_variables} className={'card-bap'} maxItems={20} SynopticCardId={2} sectionId={2} />}
                  {Number(id) !== 19 && <SynopticCard mainVariables={dashBoardInfo.main_variables} className={'card-rack-inlet'} maxItems={20} SynopticCardId={2.1} sectionId={2.1} />}
                  {Number(id) !== 19 && <SynopticCard mainVariables={dashBoardInfo.main_variables} className={'card-producto'} maxItems={20} SynopticCardId={2.2} sectionId={2.2} />}
                  {Number(id) !== 19 && <SynopticCard mainVariables={dashBoardInfo.main_variables} className={'card-rack-outlet'} maxItems={20} SynopticCardId={3.1} sectionId={3.1} />}
                  {Number(id) !== 19 && <SynopticCard mainVariables={dashBoardInfo.main_variables} className={'card-isave'} maxItems={20} SynopticCardId={3} sectionId={3} />}
                  {Number(id) !== 19 && <SynopticCard mainVariables={dashBoardInfo.main_variables} className={'card-flushing'} maxItems={20} SynopticCardId={4} sectionId={4} />}


                  {/*   <StatusIndicator section="section1" />
                  <StatusIndicator section="section2" />
                  <StatusIndicator section="section3" />
                  <StatusIndicator section="section4" /> */}
                  {/*         <StatusIndicator section="section2" />
                <StatusIndicator section="section3" />
                <StatusIndicator section="section4" />
 */}


                </div>
              </Container>

            </div>




          </React.Fragment >
        )}
    </>
  );

};

export default Synoptic;
