import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  reorderMainVariables as reorderMainVariablesApi,
} from "../../helpers/fakebackend_helper";
import { reorderMainVariablesInDashboardInfo } from "slices/favorites/reducer";





export const reorderMainVariables = createAsyncThunk(
  "mainVariables/reorderMainVariables",
  async (favoriteInfo: any, { dispatch }) => {
    try {

      const response = await reorderMainVariablesApi(favoriteInfo);

      dispatch(reorderMainVariablesInDashboardInfo(favoriteInfo));

      return favoriteInfo; 
    } catch (error) {
      throw error; 
    }
  }
);


