import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Socket, io } from 'socket.io-client';

const isLocal = window.location.hostname === '192.168.0.6';
const socketServerUrl = isLocal
  ? process.env.REACT_APP_SOCKET_SERVER_URL_LOCAL
  : process.env.REACT_APP_SOCKET_SERVER_URL;

export const SocketContext = createContext<Socket | null>(null);

interface SocketProviderProps {
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isDisconnected, setIsDisconnected] = useState(false);

  useEffect(() => {
    if (!socketServerUrl) {
      console.error('La URL del servidor Socket no está definida.');
      return; // Early return to prevent further execution
    }

    const newSocket = io(socketServerUrl, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000000,
    });

    newSocket.on('connect', () => {
      setIsDisconnected(false);
    });

    newSocket.on('disconnect', (reason) => {
      console.log(`Desconectado del servidor por: ${reason}`);
      setIsDisconnected(true);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [socketServerUrl]);

  return (
    <SocketContext.Provider value={socket}>
      {isDisconnected ? <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner />
      </div> : children}
    </SocketContext.Provider>
  );
};