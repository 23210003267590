import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, Input, FormGroup } from 'reactstrap';

interface ModalConfirmProps {
    isOpen: boolean;
    toggle: () => void;
    title: string;
    message: string;
    onConfirm: (data: any | null) => void;
    onCancel: () => void;
    iconName?: string;
    idSelected?: number | null;
    requireNameConfirmation?: boolean;
    confirmationName?: string;
    className?: string;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
    isOpen,
    toggle,
    title,
    message,
    onConfirm,
    onCancel,
    iconName,
    idSelected = null, // Default idSelected to null if not provided
    requireNameConfirmation = false,
    confirmationName = '',
    className = ''
}) => {
    const [inputName, setInputName] = useState('');

    useEffect(() => {
        if (!isOpen) {
            setInputName(''); // Reset inputName when modal is closed
        }
    }, [isOpen]);

    // Handles confirm action and modal closure
    const handleConfirm = () => {
        if (idSelected !== null) {
            onConfirm(idSelected);
        }
        toggle();
    };

    // Handles cancel action and modal closure
    const handleCancel = () => {
        onCancel();
        toggle();
    };

    const isConfirmDisabled = requireNameConfirmation && inputName !== confirmationName;

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered >
            <ModalBody className={`text-center p-5 ${className}`} >
                {iconName && <i className={iconName}></i>}
                <div className="mt-4">
                    <h4 className="mb-3">{title}</h4>
                    <p className="text-muted mb-4">{message}</p>
                    {requireNameConfirmation && (
                        <FormGroup>
                            <Input
                                type="text"
                                value={inputName}
                                onChange={(e) => setInputName(e.target.value)}
                                placeholder={`Escriba "${confirmationName}" para confirmar`}
                            />
                        </FormGroup>
                    )}
                    <div className="hstack gap-2 justify-content-center">
                        <Button color="light" onClick={handleCancel}>Cancelar</Button>
                        <Button color="success" onClick={handleConfirm} disabled={isConfirmDisabled}>Confirmar</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalConfirm;
