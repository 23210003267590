import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getParametersBackup as getParametersBackupApi,
  getParameterBackup as getParameterBackupApi,
  createParameterBackup as createParameterBackupApi,
  updateParameterBackup as updateParameterBackupApi,
  deleteParameterBackup as deleteParameterBackupApi,
  backupParametersByConnection as backupParametersByConnectionApi
} from "../../helpers/fakebackend_helper";
import { updateParameterBackupValue, updateParametersBackupByConnection } from "./reducer";
import { unitAndDecimalsDanfossVacon100Flow } from "devices/vacon";

export const getParametersBackup = createAsyncThunk(
  "parameterBackup/getParameterBackups",
  async ({ connectionId, parameterValues }: { connectionId: number, parameterValues?: any }) => {
    try {

      const response: any = await getParametersBackupApi(connectionId);
      let newParameterBackups: any = response[connectionId].parameters;

      response[connectionId].parameters.map((parameterBackup: any) => {
        const parameterIdNumber = Number(parameterBackup.parameter_id);
        newParameterBackups = unitAndDecimalsDanfossVacon100Flow(
          parameterIdNumber,
          newParameterBackups,
          parameterValues[parameterBackup.id],
          connectionId
        )
      })
      response[connectionId].parameters = newParameterBackups;

      // Devolver la respuesta de la API como payload
      return response;
    } catch (error) {
      // Manejar cualquier error que ocurra durante la llamada a la API
      return error;
    }
  }
);

/* export const getParametersBackup = createAsyncThunk(
  "parameterBackup/getParameterBackups", // Tipo de acción para Redux
  async ({ connectionId, parameterValues }: { connectionId: number, parameterValues?: any }) => {
    try {

      const response: any = await getParametersBackupApi(connectionId);
      console.log("responseParametersBackup", response)
      let newParameterBackups: any = response[connectionId].parameters;

      response[connectionId].parameters.map((parameterBackup: any) => {
        const parameterIdNumber = Number(parameterBackup.parameter_id);
        newParameterBackups = unitAndDecimalsDanfossVacon100Flow(
          parameterIdNumber,
          newParameterBackups,
          parameterValues[parameterBackup.id],
          connectionId
        )
      })
      response[connectionId].parameters = newParameterBackups;
      // Devolver la respuesta de la API como payload
      return response;
    } catch (error) {
      // Manejar cualquier error que ocurra durante la llamada a la API
      return error;
    }
  }
); */



/* export const getParameterBackup = createAsyncThunk("parameterBackup/getParameterBackup", async (parameterId: number) => {
  try {
    const response = await getParameterBackupApi(parameterId);
    console.log("response", response);
    return response;
  } catch (error) {
    return error;
  }
}); */

export const createParameterBackup = createAsyncThunk("parameterBackup/createParameterBackup", async (parameter: any) => {
  try {
    const response: any = await createParameterBackupApi(parameter);
    if (response.id) {
      parameter.id = response.id;
      parameter.supervision_name = response.supervision_name;
    }
    return parameter;
  } catch (error) {
    return { error: error };
  }
});

export const backupParametersByConnection = createAsyncThunk("parameterBackup/backupParametersByConnection", async (backupInfo: any) => {
  try {
    const response: any = await backupParametersByConnectionApi(backupInfo);
    return response;
  } catch (error) {
    return { error: error };
  }
});

export const updateParameterBackup = createAsyncThunk(
  "parameterBackup/updateParameterBackup",
  async (parameterInfo: { connection_id: number; parameter_id: any; value: any, parameterValues?: any, parameterBackup?: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateParameterBackupApi(parameterInfo); // Asegúrate de que esta función maneje el objeto completo
      // Despacha una acción si es necesario, puedes ajustar esto según tu lógica
      dispatch(updateParametersBackupByConnection(parameterInfo))
      dispatch(updateParameterBackupValue(parameterInfo));

     

      return response.data; // Ajusta esto según cómo tu API devuelva los datos
    } catch (error: any) {
      return rejectWithValue({ error: error.message }); // Maneja el error según tu lógica
    }
  }
);

export const deleteParameterBackup = createAsyncThunk("parameterBackup/deleteParameterBackup", async (connectionId: any) => {
  try {
    const response = await deleteParameterBackupApi(connectionId);
    return connectionId;
  } catch (error) {
    return error;
  }
});
